import React, { useEffect, useContext } from "react";

import AuthContext from "../context/authContext/authContext";
import { Result, Button } from "antd";
import { Link } from "react-router-dom";

function Unauthorized() {
  const { loadUser, user } = useContext(AuthContext);

  useEffect(() => {
    loadUser();
    // eslint-disable-next-line
  }, []);

  return (
    <div className="container">
      <Result
        status="403"
        title="UNAUTHORIZE ACCESS"
        subTitle="Sorry, you don't have permission to access this page'"
        extra={<Link to={"/dashboard"}>Back Home</Link>}
      />
    </div>
  );
}

export default Unauthorized;
