import React, { useEffect, useLayoutEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import {
  Form,
  message,
  notification,
  Divider,
  DatePicker,
  Button,
  InputNumber,
} from "antd";
import { UploadOutlined } from "@ant-design/icons";
import moment from "moment";
import API from "../../config/API";
import { Select, Option } from "../../components/AppSelect";
import AppSubmitButton from "../../components/AppButton";
import AppTextArea from "../../components/AppTextArea";
import InputDollar from "../../components/InputDollar";
import useApiPost from "../../hooks/useApi";
import useApiGet from "../../hooks/useApiGet";
import AppInput from "../../components/AppInput";

const { Item: Field } = Form;
const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 12 },
};
const tailLayout = {
  wrapperCol: { offset: 8, span: 12 },
};

const EditDonee = () => {
  const location = useLocation();
  let navigate = useNavigate();
  const [form] = Form.useForm();
  const [isVisible, setIsVisible] = useState(false);

  const { data, request, loading } = useApiGet(
    `/api/beneficiaries/one/${location.state.id}`
  );
  const result = useApiPost("/api/beneficiaries/");
  const [disable, setDisable] = useState(false);

  useEffect(() => {
    request();
  }, []);

  useLayoutEffect(() => {
    form.setFieldValue("role", data.role);
    form.setFieldValue("first_name", data.first_name);
    form.setFieldValue("last_name", data.last_name);
    form.setFieldValue("levelOfEduc", data.levelOfEduc);
    form.setFieldValue("email", data.email);
    form.setFieldValue("phone", data.phone);
    form.setFieldValue("dob", moment(data.dob));
    form.setFieldValue("gender", data.gender);
    form.setFieldValue("employmentHistory", data.employment_history);
    form.setFieldValue("skills", data.skills);
    form.setFieldValue("story", data.story);

    // nationality
    if (data.nationality) {
      form.setFieldValue("nationality", data.nationality.nationality);
      form.setFieldValue("stateOfOrigin", data.nationality.stateOfOrigin);
      form.setFieldValue(
        "localGovernmentArea",
        data.nationality.localGovernmentArea
      );

      // residentialAddress
      form.setFieldValue(
        "houseNo",
        data.nationality.residentialAddress.houseNo
      );
      form.setFieldValue("street", data.nationality.residentialAddress.street);
      form.setFieldValue("state", data.nationality.residentialAddress.state);
      form.setFieldValue(
        "country",
        data.nationality.residentialAddress.country
      );
    }

    if (data.bank_details) {
      form.setFieldValue("accountNo", data.bank_details.acct_no);
      form.setFieldValue("accountName", data.bank_details.acct_name);
      form.setFieldValue("bankName", data.bank_details.bank);
    }
  }, [data]);

  const handleSubmit = (values) => {
    const nationality = {
      nationality: values.nationality,
      stateOfOrigin: values.stateOfOrigin,
      localGovernmentArea: values.localGovernmentArea,
      residentialAddress: {
        houseNo: values.houseNo,
        street: values.street,
        state: values.state,
        country: values.country,
      },
    };

    const bank_details = {
      acct_no: values.accountNo,
      acct_name: values.accountName,
      bank: values.bankName,
    };

    const formData = {
      skills: values.skills,
      employment_history: values.employmentHistory,
      first_name: values.first_name,
      last_name: values.last_name,
      levelOfEduc: values.levelOfEduc,
      email: values.email,
      phone: values.phone,
      dob: values.dob,
      role: values.role,
      gender: values.gender,
      nationality,
      bank_details,
      story: values.story,
    };

    API.put(`/api/beneficiaries/update/${location.state.id}`, formData)
      .then((res) => {
        navigate("/doneeReport");
      })
      .catch((err) =>
        notification.error({
          message: "Connection Error",
          description: err ? err : "Oops something went wrong !!!",
          duration: 10,
        })
      );
  };

  return (
    <div>
      <h4>Update a beneficiary</h4>
      <Form
        {...layout}
        scrollToFirstError
        name="Donee-component"
        form={form}
        // validateMessages={validateMessages}
        onFinish={handleSubmit}
      >
        <Field
          name="role"
          label="Select donee category"
          // className="col-md-3 m-3"
          tooltip="This is a required field"
          rules={[
            {
              required: true,
              type: "string",
            },
          ]}
        >
          <Select onChange={(e) => setIsVisible(true)} style={{ width: 250 }}>
            <Option key={1} value="orphan">
              Orphan
            </Option>
            <Option key={2} value="widow">
              Widow
            </Option>
            <Option key={3} value="less-privileged">
              Less-privileged
            </Option>
          </Select>
        </Field>
        <div id="details">
          <Divider plain>Details</Divider>
          <Field
            name="first_name"
            label="First Name"
            // className="col-md-3 m-3"
            tooltip="This is a required field"
            rules={[{ required: true, type: "text" }]}
          >
            <AppInput style={{ width: 250 }} />
          </Field>
          <Field
            name="last_name"
            label="Last Name"
            // className="col-md-3 m-3"
            tooltip="This is a required field"
            rules={[{ required: true, type: "text" }]}
          >
            <AppInput style={{ width: 250 }} />
          </Field>
          <Field
            name="email"
            label="Email"
            // className="col-md-3 m-3"
            tooltip="This is a required field"
            rules={[{ required: true, type: "text" }]}
          >
            <AppInput style={{ width: 250 }} />
          </Field>
          <Field
            name="phone"
            label="Phone"
            // className="col-md-3 m-3"
            tooltip="This is a required field"
            rules={[{ required: false, type: "text" }]}
          >
            <AppInput style={{ width: 250 }} />
          </Field>
          <Field
            name="gender"
            label="Select Gender"
            // className="col-md-3 m-3"
            tooltip="This is a required field"
            rules={[
              {
                required: true,
                type: "string",
              },
            ]}
          >
            <Select style={{ width: 250 }} placeholder="Select">
              <Option key={1} value="male">
                Male
              </Option>
              <Option key={2} value="female">
                Female
              </Option>
            </Select>
          </Field>
          <Field
            name="dob"
            label="Date of Birth"
            // className="col-md-3 m-3"
            tooltip="This is a required field"
            rules={[
              {
                required: true,
                type: "date",
              },
            ]}
          >
            <DatePicker
              picker="day"
              onChange={(e) => e}
              dateFormat="dd/MM/yyyy"
              placeholderText="Enter Date"
            />
          </Field>
        </div>

        <div id="nationality">
          <Divider plain>Nationality</Divider>
          <Field
            name="nationality"
            label="Nationality"
            // className="col-md-3 m-3"
            tooltip="This is a required field"
            rules={[{ type: "text" }]}
          >
            <AppInput style={{ width: 250 }} />
          </Field>
          <Field
            name="stateOfOrigin"
            label="State Of Origin"
            // className="col-md-3 m-3"
            tooltip="This is a required field"
            rules={[{ type: "text" }]}
          >
            <AppInput style={{ width: 250 }} />
          </Field>
          <Field
            name="localGovernmentArea"
            label="LGA"
            // className="col-md-3 m-3"
            tooltip="This is a required field"
            rules={[{ type: "text" }]}
          >
            <AppInput style={{ width: 250 }} />
          </Field>
        </div>

        <div id="residential">
          <Divider>Residential Address (Optional)</Divider>
          <Field
            name="houseNo"
            label="House No"
            // className="col-md-3 m-3"
            tooltip="This is a required field"
            rules={[{ type: "text" }]}
          >
            <AppInput style={{ width: 250 }} />
          </Field>
          <Field
            name="street"
            label="Street"
            // className="col-md-3 m-3"
            tooltip="This is a required field"
            rules={[{ type: "text" }]}
          >
            <AppInput style={{ width: 250 }} />
          </Field>
          <Field
            name="state"
            label="State"
            // className="col-md-3 m-3"
            tooltip="This is a required field"
            rules={[{ type: "text" }]}
          >
            <AppInput style={{ width: 250 }} />
          </Field>
          <Field
            name="country"
            label="Country"
            // className="col-md-3 m-3"
            tooltip="This is a required field"
            rules={[{ type: "text" }]}
          >
            <AppInput style={{ width: 250 }} />
          </Field>
        </div>

        <div id="acct">
          <Divider>Account Details (Optional)</Divider>
          <Field
            name="accountNo"
            label="Accont Number"
            // className="col-md-3 m-3"
            tooltip="This is a required field"
            rules={[{ type: "number" }]}
          >
            <InputNumber style={{ width: 250 }} />
          </Field>
          <Field
            name="accountName"
            label="Account Name"
            // className="col-md-3 m-3"
            tooltip="This is a required field"
            rules={[{ type: "text" }]}
          >
            <AppInput style={{ width: 250 }} />
          </Field>
          <Field
            name="bankName"
            label="Bank Name"
            // className="col-md-3 m-3"
            tooltip="This is a required field"
            rules={[{ type: "text" }]}
          >
            <AppInput style={{ width: 250 }} />
          </Field>
        </div>

        <div id="educ">
          <Divider>Educational Details</Divider>
          <Field
            name="levelOfEduc"
            label="Level Of Education"
            // className="col-md-3 m-3"
            tooltip="This is a required field"
            rules={[{ required: true, type: "text" }]}
          >
            <Select style={{ width: 250 }} placeholder="Select">
              <Option key={0} value="Basic/Primary/Elementary">
                Basic/Primary/Elementary school
              </Option>
              <Option key={1} value="JSCE/Middle_school">
                JSCE/Middle school
              </Option>
              <Option key={2} value="SSCE/High_school">
                SSCE/High school
              </Option>
              <Option key={3} value="Diploma">
                Diploma
              </Option>
              <Option key={4} value="undergraduate">
                Undergraduate
              </Option>
              <Option key={5} value="graduate">
                Post Grad
              </Option>
            </Select>
          </Field>
          {/* <Field
                name="Skills"
                label="Skills"
                // className="col-md-3 m-3"
                tooltip="This is a required field"
                rules={[{ required: false, type: "text" }]}
              >
                <AppInput style={{ width: 250 }} />
              </Field> */}

          <Field
            name="skills"
            label="Skills"
            tooltip="Prev. job titles"
            rules={[{ required: false }]}
          >
            <Select
              showSearch
              placeholder="Hard and soft skills"
              mode="tags"
              allowClear
              style={{ width: "100%" }}
              disabled={disable}
            ></Select>
          </Field>
          <Field
            name="employmentHistory"
            label="Employment History"
            tooltip="Prev. job titles"
            rules={[{ required: false }]}
          >
            <Select
              showSearch
              placeholder="Job Titles"
              mode="tags"
              allowClear
              style={{ width: "100%" }}
              disabled={disable}
            ></Select>
          </Field>

          <Field
            name="story"
            label="Tell your story"
            // className="col-md-3 m-3"
            tooltip="This is a required field"
            rules={[{ required: true, type: "text" }]}
          >
            <AppTextArea rows={10} style={{ width: "100%" }} />
          </Field>
        </div>

        <Field {...tailLayout}>
          <AppSubmitButton disabled={disable} htmlType="submit" />
        </Field>
      </Form>
    </div>
  );
};

export default EditDonee;
