import React, { useEffect, useState, useContext } from "react";
import { Link } from "react-router-dom";
import { Table, Input, Form, Select } from "antd";
//import Swal from "sweetalert2";
import { Tooltip } from "antd";
//import EditIcon from "@material-ui/icons/Edit";
import moment from "moment";
import useApiGet from "../../hooks/useApiGet";
//import { Link } from "react-router-dom";
const { Search } = Input;
const { Item: Field } = Form;
const { Option } = Select;

const columns = [
  {
    title: "First Name",
    dataIndex: "first_name",
    render: (el) => el.toUpperCase(),
    key: "_id",
  },
  {
    title: "Last Name",
    dataIndex: "last_name",
    key: "_id",
  },
  {
    title: "Role",
    dataIndex: "role",
    key: "_id",
  },
  {
    title: "Gender",
    dataIndex: "gender",
    key: "_id",
  },
  {
    title: "Date of Birth",
    dataIndex: "dob",
    render: (el) => moment(el).format("DD/MM/YYYY"),
    key: "_id",
  },
  {
    title: "Phone",
    dataIndex: "phone",
    key: "_id",
  },
  {
    title: "Email",
    dataIndex: "email",
    key: "_id",
  },
  {
    title: "Action",
    dataIndex: "_id",
    key: "_id",
    align: "center",

    render: (id) => {
      return (
        // console.log(id),
        <span>
          <Link to="/editStaff" state={{ id: id }}>
            Edit
          </Link>
        </span>
      );
    },
  },
];

const StaffReport = () => {
  const [fetchData, setFetchData] = useState(false);

  const [q, setQ] = useState("");
  const { data, request, loading } = useApiGet("/api/users/all");

  useEffect(() => {
    request();
  }, [fetchData]);

  //window.onload((e) => setFetchData(true));

  const search = (data) => {
    return data.filter((record) => {
      return (
        record.first_name.toString().toLowerCase().indexOf(q.toLowerCase()) > -1 ||
        record.last_name.toString().toLowerCase().indexOf(q.toLowerCase()) > -1
      );
    });
  };

  return (
    <div>
      <div>
        <h6 align="center" style={{ marginLeft: "20px" }}>
          <h3>
            <em>JULIET EFEMENA FOUNDATION</em>
          </h3>
          <br />
        </h6>
        <h4 align="center"> Staffs/Users List </h4>
        <br />
        <Search
          placeholder="Search"
          onChange={(e) => setQ(e.target.value)}
          enterButton
          style={{ width: 200 }}
        />
        <br />
        <Table
          columns={columns}
          bordered
          loading={loading}
          pagination
          dataSource={search(data)}
          rowKey={(record) => record._id}
          size="small"
        />
      </div>
    </div>
  );
};
export default StaffReport;
