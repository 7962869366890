import React, { useEffect, useState } from "react";
import AppDatePicker from "../../components/AppDatePicker";
import { Select, Option } from "../../components/AppSelect";
import AppSubmitButton from "../../components/AppButton";
import useApiPost from "../../hooks/useApi";
import { Form, Input } from "antd";
import useApiGet from "../../hooks/useApiGet";
import AppInput from "../../components/AppInput";
import { Link } from "react-router-dom";

const { Item: Field } = Form;
const { Password } = Input;

const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 12 },
};
const tailLayout = {
  wrapperCol: { offset: 8, span: 12 },
};

const StaffRegistration = () => {
  const [form] = Form.useForm();
  const [isVisible, setIsVisible] = useState(false);

  const result = useApiPost("/api/users/");
  const { data, request, loading } = useApiGet("/api/users/all");
  const [disable, setDisable] = useState(false);
  useEffect(() => {
    request();
  }, []);

  const handleSubmit = (values) => {
    const data = { ...values };
    result.request(data);
    form.resetFields();
  };
  return (
    <div>
      <h4>Staff registration User</h4>
      <Form
        {...layout}
        scrollToFirstError
        name="User-component"
        form={form}
        // validateMessages={validateMessages}
        onFinish={handleSubmit}
      >
        <Field
          name="first_name"
          label="First Name"
          tooltip="This is a required field"
          rules={[{ required: true, type: "text" }]}
        >
          <AppInput style={{ width: 250 }} />
        </Field>
        <Field
          name="last_name"
          label="Last Name"
          tooltip="This is a required field"
          rules={[{ required: true, type: "text" }]}
        >
          <AppInput style={{ width: 250 }} />
        </Field>
        <Field
          name="gender"
          label="Select Gender"
          tooltip="This is a required field"
          rules={[
            {
              required: true,
              type: "string",
            },
          ]}
        >
          <Select style={{ width: 250 }} placeholder="Select">
            <Option key={1} value="male">
              Male
            </Option>
            <Option key={2} value="female">
              Female
            </Option>
          </Select>
        </Field>

        <Field
          name="role"
          label="Role"
          tooltip="This is a required field"
          rules={[
            {
              required: true,
              type: "string",
            },
          ]}
        >
          <Select style={{ width: 250 }} placeholder="Select">
            <Option key={1} value="admin">
              Admin
            </Option>
            <Option key={2} value="volunteer">
              Volunteer
            </Option>
            <Option key={3} value="staff">
              Staff
            </Option>
          </Select>
        </Field>

        <Field
          name="dob"
          label="Date of Birth"
          tooltip="This is a required field"
          rules={[
            {
              required: true,
              type: "date",
            },
          ]}
        >
          <AppDatePicker
            picker="day"
            onChange={(e) => e}
            dateFormat="dd/MM/yyyy"
            placeholderText="Enter Date"
            style={{ width: 250 }}
          />
        </Field>

        <Field
          name="email"
          label="Email"
          tooltip="This is a required field"
          rules={[{ required: true, type: "email" }]}
        >
          <AppInput style={{ width: 250 }} />
        </Field>

        <Field
          name="phone"
          label="Phone"
          tooltip="This is a required field"
          rules={[{ required: true, type: "text" }]}
        >
          <AppInput style={{ width: 250 }} />
        </Field>

        <Field
          name="password"
          autoComplete="false"
          label="Password"
          tooltip="This is a required field"
          rules={[{ required: true, type: "text" }]}
        >
          <Password placeholder="input password" style={{ width: 250 }} />
        </Field>
        <Field {...tailLayout}>
          <AppSubmitButton disabled={disable} htmlType="submit" />
          <br />
          <Link to="/staffReport">
            <p>View Staff Report?</p>
          </Link>
        </Field>
      </Form>
    </div>
  );
};

export default StaffRegistration;
