import React from "react";
import { DatePicker } from "antd";
export default function AppDatePicker({ value, width = "100%", ...otherProps }) {
  return (
    <>
      <DatePicker
        value={value}
        className="form-control"
        width={width}
        {...otherProps}
      />
    </>
  );
}
