import React, { useReducer, useEffect, useState } from "react";
import axios from "axios";
import authReducer from "../authContext/authReducer";
import AuthContext from "../authContext/authContext";
import setAuthToken from "../../utils/setAuthToken";
import API from "../../config/API";
import config from "../../config/default.json";
import {
  REGISTER_SUCCESS,
  REGISTER_FAIL,
  LOGIN_SUCCESS,
  LOGIN_FAIL,
  USER_LOADED,
  AUTH_ERROR,
  LOGOUT,
  CLEAR_ERRORS,
} from "../types";
import { notification } from "antd";

const AuthState = (props) => {
  const intialState = {
    token: localStorage.getItem("token"),
    isAuthenticated: null,
    loading: true,
    user: null,
    error: null,
  };
  const [state, dispatch] = useReducer(authReducer, intialState);

  // Load User
  const loadUser = async () => {
    // console.log(localStorage);
    if (localStorage.token) {
      setAuthToken(localStorage.token);
    }

    try {
      const res = await axios.get(config.baseURL + "api/auth");

      dispatch({
        type: USER_LOADED,
        payload: res.data,
      });
    } catch (err) {
      console.log(err);
      dispatch({
        type: AUTH_ERROR,
      });
    }
  };

  // useEffect(() => {
  //   return () => mounted = true
  // },[])

  //Register User
  const register = async (formData) => {
    const config = {
      header: {
        "Content-Type": "application/json",
      },
    };
    try {
      const res = await API.post("api/users/", formData, config);
      dispatch({
        type: REGISTER_SUCCESS,
        payload: res.data,
      });
      loadUser();
    } catch (err) {
      notification.error({
        message: "Error",
        description: err && err.response.data,
      });
      dispatch({
        type: REGISTER_FAIL,
        payload: err,
      });
    }
  };

  //login user
  const login = async (formData) => {
    const config = {
      header: {
        "Content-Type": "application/json",
      },
    };
    try {
      const res = await API.post("/api/auth", formData, config);
      dispatch({
        type: LOGIN_SUCCESS,
        payload: res.data,
      });
      localStorage.setItem("token", res.data.token);
      loadUser();
    } catch (err) {
      notification.error({ message: err.response.data });
      dispatch({
        type: LOGIN_FAIL,
        payload: err.response.data,
      });
    }
  };

  const setError = (err) => {
    dispatch({
      type: REGISTER_FAIL,
      payload: [{ msg: err }],
    });
  };

  // Logout
  const logout = () => dispatch({ type: LOGOUT });

  // Clear Errors
  const clearErrors = () => dispatch({ type: CLEAR_ERRORS });
  return (
    <AuthContext.Provider
      value={{
        token: state.token,
        isAuthenticated: state.isAuthenticated,
        user: state.user,
        error: state.error,
        loading: state.loading,
        register,
        login,
        loadUser,
        logout,
        clearErrors,
        setError,
      }}
    >
      {props.children}
    </AuthContext.Provider>
  );
};

export default AuthState;
