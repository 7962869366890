import React, { useState, useEffect } from "react";
import useApiGet from "../hooks/useApiGet";
import { Statistic } from "antd";
import "../css/impact.css";
import {
  UsergroupAddOutlined,
  ProjectOutlined,
  FundOutlined,
  WomanOutlined,
} from "@ant-design/icons";

export const Stat = ({ title }) => {
  // const [totalDonation, setTotalDonation] = useState(0);
  const { data: donationData, request: donationRequest } =
    useApiGet("/api/donations/");
  const {
    data: beneficiariesData,
    request: benefeficiariesRequest,
    loading,
  } = useApiGet("/api/beneficiaries/");
  const totalOrphan = beneficiariesData.filter(function (element) {
    return element.role === "orphan";
  }).length;
  const totalWidow = beneficiariesData.filter(function (element) {
    return element.role === "widow";
  }).length;
  const totalDonation = donationData.reduce(
    (acc, { amount }) => acc + amount,
    0
  );

  useEffect(() => {
    benefeficiariesRequest();
    donationRequest();
  }, []);
  // console.log(totalOrphan, totalWidow);
  return (
    <>
      {/* <div id="widow" className="text-center">
        <div className="container">
          <div className="col-md-10 col-md-offset-1 section-title">
            <h2>Widow Cause</h2>
          </div>
          <div className="row">
            <div className="col-xs-6 col-md-3">
              <img />
              <h3>Clean water in urban Area</h3>
              <p>lorem ipsum dolor sit amet, consectetur adip</p>
            </div>
            {props.data
              ? props.data.map((d, i) => (
                  <div key={`${d.title}-${i}`} className="col-xs-6 col-md-3">
                    {" "}
                    <img src={d.url} alt={d.title} width="250px" height="300px" />
                    <h3>{d.title}</h3>
                    <p>{d.text}</p>
                  </div>
                ))
              : "Loading..."}
          </div>
        </div>
      </div> */}
      <div id="stat">
        <div>
          <div className="section-title">
            <h2 align="center">{title}</h2>
          </div>

          <div>
            <div className="stat_row">
              <div className="stat_item">
                <Statistic
                  title="Sponsored Orphans"
                  value={totalOrphan ? totalOrphan : 0}
                  prefix={<UsergroupAddOutlined />}
                />
              </div>
              <div className="stat_item">
                <Statistic
                  title="Empowered Widows"
                  value={totalWidow ? totalWidow : 0}
                  prefix={<WomanOutlined />}
                />
              </div>
              <div className="stat_item">
                <Statistic
                  title="Completed Projects"
                  value={1}
                  prefix={<ProjectOutlined />}
                />
              </div>
              <div className="stat_item">
                <Statistic
                  title="Ongoing Projects"
                  value={2}
                  prefix={<FundOutlined />}
                />
              </div>
              <div className="stat_item">
                <Statistic
                  title="Total Donations (NGN)"
                  value={totalDonation ? totalDonation : 0}
                  precision={2}
                  prefix="₦"
                />
              </div>
            </div>
          </div>
        </div>
        <br />
      </div>
    </>
  );
};
