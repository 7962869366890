import axios from "axios";
import config from "./default.json";
export default axios.create({
  baseURL: config.baseURL,
  // baseURL: config.baseURLProduction,
  timeout: 10000,
});

// https://jefbackend-production.up.railway.app/api/
//  "http://54.144.133.120:8080/",
// "http://localhost:8000/"
