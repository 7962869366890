import React, { useState } from "react";
import AppSubmitButton from "../../components/AppButton";
import AppTextArea from "../../components/AppTextArea";
import useApiPost from "../../hooks/useApi";
import { Form, Button, Upload, DatePicker } from "antd";
import { UploadOutlined } from "@ant-design/icons";
import AppInput from "../../components/AppInput";
import { Select, Option } from "../../components/AppSelect";

const { Item: Field } = Form;
const { RangePicker } = DatePicker;

const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 12 },
};
const tailLayout = {
  wrapperCol: { offset: 8, span: 12 },
};

const AddProject = () => {
  const [visible, setVisible] = useState(false);
  const [form] = Form.useForm();

  const result = useApiPost("/api/projects/");
  // const { data, request, loading } = useApiGet("/api/projects//all");
  const [disable, setDisable] = useState(false);

  // useEffect(() => {
  //   request();
  // }, []);

  const dummyRequest = ({ file, onSuccess }) => {
    setTimeout(() => {
      onSuccess("ok");
    }, 0);
  };

  const props = {
    type: "drag",
    // beforeUpload: (file) => {
    //   const isJPEG = file.type === "image/jpeg";
    //   if (!isJPEG) {
    //     message.error(`${file.name} is not a jpeg file`);
    //   }
    //   return isJPEG || Upload.LIST_IGNORE;
    // },
    // maxCount: 1,
    multiple: true,
    // onChange: (e) => setFiles(e.file.originFileObj),
    customRequest: dummyRequest,
  };

  const handleSubmit = (values) => {
    const formData = new FormData();

    // if (values.mediaLink && values.mediaLink.length > 0) {
    for (var i = 0; i < values.mediaLink.fileList.length; i++) {
      formData.append("mediaLink", values.mediaLink.fileList[i].originFileObj);
    }
    // }

    if (visible && values.category === "current") {
      for (var j = 0; j < values.mediaLinkPrp.fileList.length; j++) {
        formData.append(
          "mediaLinkPrp",
          values.mediaLinkPrp.fileList[j].originFileObj
        );
      }
    }

    const postedBy = "63877e3a088b6e368d4f6301";
    formData.append("startDate", values.date[0]);
    formData.append("endDate", values.date[1]);
    formData.append("title", values.title);
    formData.append("category", values.category);
    formData.append("description", values.description);
    formData.append("address", values.location);
    formData.append("longitude", values.longitude ? values.longitude : "");
    formData.append("latitude", values.latitude ? values.latitude : "");
    formData.append("projectStatus", values.projectStatus);
    formData.append("postedBy", postedBy);

    result.request(formData);
    form.resetFields();
  };

  const onChangeStatus = () => {
    if (form.getFieldValue("category") === "current") {
      setVisible(true);
    } else {
      setVisible(false);
    }
  };

  return (
    <div>
      <h4>Add Project</h4>
      <Form
        {...layout}
        scrollToFirstError
        name="Project-component"
        form={form}
        // validateMessages={validateMessages}
        onFinish={handleSubmit}
        // layout="vertical"
      >
        <Field
          name="title"
          label="Title of Event/Project"
          // className="col-md-3 m-3"
          tooltip="This is a required field"
          rules={[{ required: true, type: "text" }]}
        >
          <AppInput style={{ width: "100%" }} />
        </Field>

        <Field
          name="category"
          label="Select Status"
          tooltip="This is a required field"
          style={{ width: "100%" }}
          rules={[
            {
              required: true,
              type: "string",
            },
          ]}
        >
          <Select
            style={{ width: "100%" }}
            placeholder="Select"
            onChange={onChangeStatus}
          >
            <Option key={1} value="post">
              Post Project
            </Option>
            <Option key={2} value="current">
              Current Project
            </Option>
            <Option key={3} value="future">
              Future Project
            </Option>
          </Select>
        </Field>
        <Field
          name="description"
          label="Description"
          // className="col-md-3 m-3"
          tooltip="Description of the project"
          rules={[{ required: true, type: "text" }]}
        >
          <AppTextArea style={{ width: "100%" }} />
        </Field>

        <Field
          name="location"
          label="Address/Location"
          // className="col-md-3 m-3"
          tooltip="Location of the project"
          rules={[{ required: true, type: "text" }]}
        >
          <AppTextArea style={{ width: "100%" }} />
        </Field>

        <Field
          name="mediaLink"
          label="Upload"
          tooltip="This is a required field"
          rules={[{ required: true }]}
        >
          <Upload
            disabled={disable}
            getValueFromEvent={({ file }) => file.originFileObj}
            {...props}
          >
            <Button disabled={disable} icon={<UploadOutlined />}>
              Click to Upload
            </Button>
          </Upload>
        </Field>
        {visible ? (
          <Field
            name="mediaLinkPrp"
            label="Upload Proposed images"
            tooltip="This is a required field"
            rules={[{ required: true }]}
          >
            <Upload
              getValueFromEvent={({ file }) => file.originFileObj}
              {...props}
            >
              <Button icon={<UploadOutlined />}>Click to Upload</Button>
            </Upload>
          </Field>
        ) : null}

        <Field
          name="longitude"
          label="Enter Longitude"
          tooltip="Enter Longitude"
          rules={[{ type: "text" }]}
          style={{ width: "100%" }}
        >
          <AppInput style={{ width: "100%" }} />
        </Field>
        <Field
          name="latitude"
          label="Enter Latitude"
          tooltip="Enter Latitude"
          style={{ width: "100%" }}
          rules={[{ type: "text" }]}
        >
          <AppInput style={{ width: "100%" }} />
        </Field>
        <Field
          name="date"
          label="Date"
          style={{ width: "100%" }}
          // className="col-md-3 m-3"
          tooltip="This is a required field"
          rules={[
            {
              required: true,
              // type: "array",
            },
          ]}
        >
          <RangePicker format="YYYY-MM-DD" />
        </Field>

        <Field {...tailLayout}>
          <AppSubmitButton
            disabled={result.loading}
            loading={result.loading}
            htmlType="submit"
          />
        </Field>
      </Form>
    </div>
  );
};

export default AddProject;
