import React from "react";

function GalleryVideo() {
  return (
    <div>
      <h3>Upload Gallery Videos</h3>
    </div>
  );
}

export default GalleryVideo;
