import React, { useEffect, useState } from "react";
import AppSubmitButton from "../../components/AppButton";
import AppTextArea from "../../components/AppTextArea";
import config from "../../config/default.json";
import useApiPost from "../../hooks/useApi";
import {
  Form,
  Button,
  Upload,
  DatePicker,
  Image,
  Divider,
  message,
  notification,
} from "antd";
import { DeleteOutlined, UploadOutlined } from "@ant-design/icons";
import useApiGet from "../../hooks/useApiGet";
import AppInput from "../../components/AppInput";
import { useNavigate, useLocation } from "react-router-dom";
import moment from "moment";
import useApiUpdate from "../../hooks/useAPIUpdate";
import AppDatePicker from "../../components/AppDatePicker";
import { Select, Option } from "../../components/AppSelect";

import { UploadProps } from "antd";
import { useLayoutEffect } from "react";
import API from "../../config/API";
//import { Button, Upload } from "antd";

const { Item: Field } = Form;
//const { RangePicker } = DatePicker;

const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 12 },
};
const tailLayout = {
  wrapperCol: { offset: 8, span: 12 },
};

const EditProject = () => {
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const [files, setFiles] = useState([]);
  const location = useLocation();
  const [fileURL, setFileURL] = useState([]);

  const [disable, setDisable] = useState(false);
  // const result = useApiUpdate("/api/events/details/" + location.state.id);
  const { data, request } = useApiGet(
    "/api/projects/projectsByID/" + location.state.id
  );
  useEffect(() => {
    request();
    //    singleRequest();
  }, [location]);

  useLayoutEffect(() => {
    if (data && data.description)
      form.setFieldsValue({
        description: data.description,
        title: data.title,
        startDate: moment(data.startDate),
        endDate: moment(data.endDate),
        postedBy: data.postedBy,
        mediaLink: data.Upload,
        longitude: data.longitude,
        latitude: data.latitude,
        category: data.category,
      });
  }, [data]);

  const props = {
    type: "drag",
    // beforeUpload: (file) => {
    //   const isJPEG = file.type === "image/jpeg";
    //   if (!isJPEG) {
    //     message.error(`${file.name} is not a jpeg file`);
    //   }
    //   return isJPEG || Upload.LIST_IGNORE;
    // },
    // maxCount: 1,
    multiple: true,
    //onChange: (e) => setFiles(e.file.originFileObj),
    customRequest: ({ file, onSuccess }) => {
      setTimeout(() => {
        onSuccess("ok");
      }, 0);
    },
  };

  const handleSubmit = (values) => {
    const formData = new FormData();

    for (var i = 0; i < values.mediaLink.fileList.length; i++) {
      formData.append("mediaLink", values.mediaLink.fileList[i].originFileObj);
    }

    if (values.category === "current") {
      for (var j = 0; j < values.mediaLinkPrp.fileList.length; j++) {
        formData.append(
          "mediaLinkPrp",
          values.mediaLinkPrp.fileList[j].originFileObj
        );
      }
    }

    const postedBy = "63877e3a088b6e368d4f6301";
    formData.append("startDate", values.startDate);
    formData.append("endDate", values.endDate);
    formData.append("title", values.title);
    formData.append("description", values.description);
    formData.append("longitude", values.longitude);
    formData.append("latitude", values.latitude);
    formData.append("category", values.category);
    formData.append("postedBy", postedBy);

    // for (var i = 0; i < data.upload.length; i++) {
    //   formData.append("uploadPrev", data.upload[i]);
    // }

    // for (var i = 0; i < data.uploadPrp.length; i++) {
    //   formData.append("uploadPrevPrp", data.uploadPrp[i]);
    // }

    console.log(values);

    API.put(`/api/projects/update/${location.state.id}`, formData)
      .then((res) => {
        // console.log(res.data);
        // navigate("/staffReport");
      })
      .catch((err) =>
        notification.error({
          message: "Connection Error",
          description: err ? err : "Oops something went wrong !!!",
          duration: 10,
        })
      );
  };

  return (
    <div>
      <h4>Edit Project</h4>
      <Form
        {...layout}
        scrollToFirstError
        name="Project-component"
        form={form}
        // validateMessages={validateMessages}
        onFinish={handleSubmit}
      >
        <Field
          name="title"
          label="Title of Project"
          tooltip="This is a required field"
          rules={[{ required: true, type: "text" }]}
        >
          <AppInput style={{ width: 250 }} />
        </Field>
        <Field
          name="category"
          label="Select Status"
          tooltip="This is a required field"
          rules={[
            {
              required: true,
              type: "string",
            },
          ]}
        >
          <Select style={{ width: 250 }} placeholder="Select">
            <Option key={1} value="post">
              Post Project
            </Option>
            <Option key={2} value="current">
              Current Project
            </Option>
            <Option key={3} value="future">
              Future Project
            </Option>
          </Select>
        </Field>
        <Field
          name="description"
          label="Description"
          tooltip="Description of the project"
          rules={[{ type: "text" }]}
        >
          <AppTextArea style={{ width: 350 }} />
        </Field>

        <div id="event-media" className="project-media">
          <Divider orientation="center"> Project Media </Divider>
          {data && data.upload && data.upload.length > 0
            ? data.upload.map((media, idx) => (
                <div>
                  <Image
                    // id="img"
                    width={200}
                    height={200}
                    // style={{ width: 100, height: 200 }}
                    src={`${config.baseURL}api/projects/downloadProjectMedia?fileName=${media.path}`}
                  />
                  <DeleteOutlined
                    size="l"
                    onClick={(e) => {
                      API.delete(
                        `${config.baseURL}api/projects/deleteProjectMedia/${data._id}?fileName=${media.path}&fileIdx=${idx}&type=1`
                      )
                        .then(() => {
                          request();
                          message.success({
                            content: "Successful",
                            duration: 2,
                          });
                        })
                        .catch((err) =>
                          message.error({
                            content: "Oops! something went wrong. Try again",
                          })
                        );
                    }}
                  />
                </div>
              ))
            : null}
        </div>

        <Field
          name="mediaLink"
          label="Upload project media"
          tooltip="This is a required field"
          rules={[{ required: false }]}
        >
          <Upload {...props}>
            <Button icon={<UploadOutlined />}>Upload</Button>
          </Upload>
        </Field>

        <div id="event-media" className="project-media-proposed">
          <Divider orientation="center"> Proposed Project Media </Divider>
          {data && data.uploadPrp && data.uploadPrp.length > 0
            ? data.uploadPrp.map((media, idx) => (
                <div>
                  <Image
                    // id="img"
                    width={200}
                    height={200}
                    // style={{ width: 100, height: 200 }}
                    src={`${config.baseURL}api/projects/downloadProjectMedia?fileName=${media.path}`}
                  />
                  <DeleteOutlined
                    size="l"
                    onClick={(e) => {
                      API.delete(
                        `${config.baseURL}api/projects/deleteProjectMedia/${data._id}?fileName=${media.path}&fileIdx=${idx}&type=2`
                      )
                        .then(() => {
                          request();
                          message.success({
                            content: "Successful",
                            duration: 2,
                          });
                        })
                        .catch((err) =>
                          message.error({
                            content: "Oops! something went wrong. Try again",
                          })
                        );
                    }}
                  />
                </div>
              ))
            : null}
        </div>

        <Field
          name="mediaLinkPrp"
          label="Upload Proposed Media"
          tooltip="This is a required field"
          rules={[{ required: false }]}
        >
          <Upload {...props}>
            <Button icon={<UploadOutlined />}>Upload</Button>
          </Upload>
        </Field>

        <Field
          name="longitude"
          label="Enter Longitude"
          tooltip="Enter Longitude"
          rules={[{ type: "text" }]}
        >
          <AppInput style={{ width: 350 }} />
        </Field>
        <Field
          name="latitude"
          label="Enter Latitude"
          tooltip="Enter Latitude"
          rules={[{ type: "text" }]}
        >
          <AppInput style={{ width: 350 }} />
        </Field>

        <Field
          name="startDate"
          label="Select start Date"
          tooltip="This is a required field"
          rules={[
            {
              required: true,
              type: "date",
            },
          ]}
        >
          <DatePicker
            picker="day"
            onChange={(e) => e}
            dateFormat="dd/MM/yyyy"
            placeholderText="Enter Date"
          />
        </Field>
        <Field
          name="endDate"
          label="Select End Date"
          tooltip="This is a required field"
          rules={[
            {
              required: true,
              type: "date",
            },
          ]}
        >
          <DatePicker
            picker="day"
            onChange={(e) => e}
            dateFormat="dd/MM/yyyy"
            placeholderText="Enter Date"
          />
        </Field>
        <Field {...tailLayout}>
          <AppSubmitButton disabled={disable} htmlType="submit" />
        </Field>
      </Form>
    </div>
  );
};

export default EditProject;
