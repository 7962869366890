import React, { useContext, useState, useEffect } from "react";
import moment from "moment";
import "./dashBoard.css";
import AuthContext from "../../context/authContext/authContext";
import useApiGet from "../../hooks/useApiGet";
import API from "../../config/API";
import "./styles.css";
import { Spin } from "antd";
import { Stat } from "../../components/impact";

const Dashboard = () => {
  const { isAuthenticated, user } = useContext(AuthContext);

  const {
    data: beneficiariesData,
    request: benefeficiariesRequest,
    loading,
  } = useApiGet("/api/beneficiaries/");
  const orphans = beneficiariesData.filter(function (element) {
    return element.role === "orphan";
  });
  const widows = beneficiariesData.filter(function (element) {
    return element.role === "widow";
  });
  useEffect(() => {
    benefeficiariesRequest();
  }, []);
  let sumOrphan = 0;
  let countOrphan = 0;

  orphans.forEach((el) => {
    sumOrphan += moment().diff(el.dob, "years", false);
    countOrphan++;
  });

  const averageAgeOrphan = sumOrphan / countOrphan;

  let sumWidow = 0;
  let countWidow = 0;

  widows.forEach((el) => {
    sumWidow += moment().diff(el.dob, "years", false);
    countWidow++;
  });

  const averageAgeWidow = sumWidow / countWidow;
  // console.log(averageAge);
  console.log(beneficiariesData);
  return (
    <div style={{ width: "100%", overFlow: "hidden" }}>
      {" "}
      <Stat title="Dashboard" />
      <div className="statistics-container">
        <div className="stat-box">
          <span>Average age of orphans:</span>
          <h2>{isNaN(averageAgeOrphan) ? 0 : Math.round(averageAgeOrphan)}</h2>
        </div>
        <div className="stat-box">
          <span>Average age of widows:</span>
          <h2>{isNaN(averageAgeWidow) ? 0 : Math.round(averageAgeWidow)}</h2>
        </div>
      </div>
    </div>
  );
};
export default Dashboard;
