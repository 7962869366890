export const MoreABout = (props) => {
  return (
    <div id="more" data-aos="fade-left">
      <div className="container">
        <div className="col-md-16  section-title">
          <h2 align="center">More about JULIET EFEMENA FOUNDATION</h2>
        </div>
        <p style={{ fontSize: "1.6rem", lineHeight: "1.5" }}>
          <strong>This passion </strong> has been in me for over twenty years,
          and in an effort to achieve this, I decided to share my thought with
          some friends who bought the ideas, and we came together to form a Club
          named - Virtuous Women. It was duly registered with C.A.C on 27th
          September 2007. Unfortunately, the expected results were not achieved,
          hence the returned dream that give birth to this foundation. <br />{" "}
          <br />
          <strong>Our target</strong> - At the early stage of this foundation
          (0-5years) it is our target to remove 10 orphans/vulnerable children
          yearly from the street by sending them to school, learning trades and
          also empowering 10 widows who are in need to start a small scale
          businesses from there they can start building up their homes by
          removing their children from the street, and also to create awareness
          among the people regarding AIDS, DRUGS AND ALCOHOL ABUSE by so doing
          the society will be sanitized and will be a better place for all of
          us. <br /> <br />
          <strong> But how can this be achieved? </strong> Presently we have 2
          children in school, already empowered 5 widows on 30th December 2022.
          But looking at the vision ahead of us, we need people to key into this
          vision to foster our program by donating towards this great work so
          that more people can be beneficiaries. This is another way of service
          to our God in heaven and the world on earth.
        </p>
        <br />
      </div>
    </div>
  );
};
