import { useState } from "react";
import { notification } from "antd";
import API from "../config/API";

const useApiUp = (endPoint) => {
  const [data, setData] = useState([]);
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);

  const request = (id, dataObj, ...args) => {
    setLoading(true);
    API.put(endPoint + id, dataObj, ...args)
      .then((res) => {
        setData(res.data);
        setLoading(false);
        success();
      })
      .catch((err) => {
        setError(err);
        setLoading(false);
        errorMsg();
      });
  };

  const success = () => {
    notification.success({
      message: "Success !!!",
      description: "information successfullly updated",
      duration: 5,
    });
  };

  const errorMsg = () => {
    notification.error({
      message: "Connection Error",
      description: "Oops something went wrong !!!",
      duration: 10,
    });
  };

  return { data, error, loading, request };
};
export default useApiUp;
