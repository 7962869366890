import React, { useEffect, useState } from "react";
import AppSubmitButton from "../../components/AppButton";
import AppTextArea from "../../components/AppTextArea";
import useApiPost from "../../hooks/useApi";
import { Form, Button, Upload, DatePicker } from "antd";
import { UploadOutlined } from "@ant-design/icons";
import useApiGet from "../../hooks/useApiGet";
import AppInput from "../../components/AppInput";

const { Item: Field } = Form;
const { RangePicker } = DatePicker;

const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 12 },
};
const tailLayout = {
  wrapperCol: { offset: 8, span: 12 },
};

const AddEvent = () => {
  const [form] = Form.useForm();

  const result = useApiPost("/api/events/");
  const { data, request, loading } = useApiGet("/api/events//all");
  const [disable, setDisable] = useState(false);

  useEffect(() => {
    request();
  }, []);

  const props = {
    type: "drag",
    // beforeUpload: (file) => {
    //   const isJPEG = file.type === "image/jpeg";
    //   if (!isJPEG) {
    //     message.error(`${file.name} is not a jpeg file`);
    //   }
    //   return isJPEG || Upload.LIST_IGNORE;
    // },
    // maxCount: 1,
    multiple: true,
    // onChange: (e) => setFiles(e.file.originFileObj),
    customRequest: ({ file, onSuccess }) => {
      setTimeout(() => {
        onSuccess("ok");
      }, 0);
    },
  };

  const handleSubmit = (values) => {
    const formData = new FormData();

    for (var i = 0; i < values.mediaLink.fileList.length; i++) {
      formData.append("mediaLink", values.mediaLink.fileList[i].originFileObj);
    }

    const postedBy = "63877e3a088b6e368d4f6301";
    formData.append("startDate", values.date[0]);
    formData.append("endDate", values.date[1]);
    formData.append("title", values.title);
    formData.append("description", values.description);
    formData.append("postedBy", postedBy);

    result.request(formData);
    form.resetFields();
  };

  return (
    <div>
      <h4>Add Event/Project</h4>
      <Form
        {...layout}
        scrollToFirstError
        name="Event-component"
        form={form}
        // validateMessages={validateMessages}
        onFinish={handleSubmit}
      >
        <Field
          name="title"
          label="Title of Event/Project"
          // className="col-md-3 m-3"
          tooltip="This is a required field"
          rules={[{ required: true, type: "text" }]}
        >
          <AppInput style={{ width: 250 }} />
        </Field>

        <Field
          name="description"
          label="Description"
          // className="col-md-3 m-3"
          tooltip="Description of the project/event"
          rules={[{ type: "text" }]}
        >
          <AppTextArea style={{ width: 350 }} />
        </Field>

        {/* <Field
          name="upload"
          label="Upload"
          tooltip="Only Jpeg files are acceptable"
          rules={[{ required: true }]}
        >
          <Upload getValueFromEvent={({ file }) => file.originFileObj} {...props}>
            <Button disabled={disable} icon={<UploadOutlined />}>
              Click to Upload
            </Button>
          </Upload>
        </Field> */}
        <Field
          name="mediaLink"
          label="Upload"
          tooltip="This is a required field"
          rules={[{ required: true }]}
        >
          <Upload
            disabled={disable}
            getValueFromEvent={({ file }) => file.originFileObj}
            {...props}
          >
            <Button disabled={disable} icon={<UploadOutlined />}>
              Click to Upload
            </Button>
          </Upload>
        </Field>

        <Field
          name="date"
          label="Date "
          // className="col-md-3 m-3"
          // tooltip="This is a required field"
          // rules={[
          //   {
          //     required: true,
          //     type: "array",
          //   },
          // ]}
        >
          <RangePicker
            showTime={{
              format: "HH:mm",
            }}
            format="YYYY-MM-DD HH:mm"
            // onChange={(value, dateString) => value}
            // onOk={(value) => {
            //   console.log("onOk: ", value);
            // }}
          />
        </Field>
        {/* <Field
          name="endDate"
          label="Select End Date"
          // className="col-md-3 m-3"
          tooltip="This is a required field"
          rules={[
            {
              required: true,
              type: "date",
            },
          ]}
        >
          <AppDatePicker
            picker="day"
            onChange={(e) => e}
            dateFormat="dd/MM/yyyy"
            placeholderText="Enter Date"
          />
        </Field> */}
        <Field {...tailLayout}>
          <AppSubmitButton disabled={disable} htmlType="submit" />
        </Field>
      </Form>
    </div>
  );
};

export default AddEvent;
