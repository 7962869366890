import React, { useEffect, useState } from "react";
import { Table, Input } from "antd";
//import EditIcon from "@material-ui/icons/Edit";
import useApiGet from "../../hooks/useApiGet";
const { Search } = Input;

const columns = [
  {
    title: "First Name",
    dataIndex: "first_name",
    render: (el) => el.toUpperCase(),
    key: "_id",
  },
  {
    title: "Last Name",
    dataIndex: "last_name",
    key: "_id",
  },
  {
    title: "Email",
    dataIndex: "email",
    key: "_id",
  },
];

const SponsorReport = () => {
  const [q, setQ] = useState("");
  const { data, request, loading } = useApiGet("/api/sponsors/");

  useEffect(() => {
    request();
  }, []);

  //window.onload((e) => setFetchData(true));

  const search = (data) => {
    return data.filter((record) => {
      return (
        record.description.toString().toLowerCase().indexOf(q.toLowerCase()) > -1 ||
        record.category.toString().toLowerCase().indexOf(q.toLowerCase()) > -1
      );
    });
  };

  return (
    <div>
      <div>
        <h6 align="center" style={{ marginLeft: "20px" }}>
          <h3>
            <em>JULIET EFEMENA FOUNDATION</em>
          </h3>
          <br />
        </h6>
        <h4 align="center"> Sponsor Report </h4>
        <br />
        <Search
          placeholder="Search"
          onChange={(e) => setQ(e.target.value)}
          enterButton
          style={{ width: 200 }}
        />
        <br />
        <Table
          columns={columns}
          bordered
          //  loading={loading}
          pagination
          dataSource={data}
          rowKey={(record) => record._id}
          size="small"
        />
      </div>
    </div>
  );
};
export default SponsorReport;
