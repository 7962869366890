import React, { useEffect, useState } from "react";
import AppSubmitButton from "../../components/AppButton";
import useApiPost from "../../hooks/useApi";
import { Form } from "antd";
import useApiGet from "../../hooks/useApiGet";
import AppInput from "../../components/AppInput";

const { Item: Field } = Form;

const Sponsor = () => {
  const [form] = Form.useForm();

  const result = useApiPost("/api/sponsors/");
  const { data, request, loading } = useApiGet("/api/sponsors/");
  const [disable, setDisable] = useState(false);

  useEffect(() => {
    request();
  }, []);

  const handleSubmit = (values) => {
    const data = { ...values };
    result.request(data);
    form.resetFields();
  };

  return (
    <div>
      <h4>Join Us</h4>
      <Form
        layout="vertical"
        scrollToFirstError
        name="Sponsor-component"
        form={form}
        className="form-row"
        onFinish={handleSubmit}
      >
        <Field
          name="email"
          label="Enter E-mail"
          className="col-md-3 m-3"
          tooltip="This is a required field"
          rules={[{ required: true, type: "email" }]}
        >
          <AppInput style={{ width: 250 }} />
        </Field>
        <Field
          name="first_name"
          label="First Name"
          className="col-md-3 m-3"
          tooltip="This is a required field"
          rules={[{ required: true, type: "text" }]}
        >
          <AppInput style={{ width: 250 }} />
        </Field>
        <Field
          name="last_name"
          label="Last Name"
          className="col-md-3 m-3"
          tooltip="This is a required field"
          rules={[{ required: true, type: "text" }]}
        >
          <AppInput style={{ width: 250 }} />
        </Field>
        <Field className="col-md-4 m-3">
          <AppSubmitButton disabled={disable} htmlType="submit" />
        </Field>
      </Form>
    </div>
  );
};

export default Sponsor;
