import React, { useState } from "react";
import { Link } from "react-scroll";
import { Link as AppLink } from "react-router-dom";
import { Form, Modal, Input, Button, InputNumber, notification,Space } from "antd";
import { useFlutterwave, closePaymentModal } from "flutterwave-react-v3";
import "../css/navigation.css";
import useApiPost from "../hooks/useApi";
import CurrencyInput from 'react-currency-input-field';

const { Item } = Form;

export const Navigation = ({ data }) => {
  // console.log(data);
  const result = useApiPost("/api/donations/");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [details, setDetails] = useState({});
  const [amount, setAmount] = useState();
  const [form] = Form.useForm();
  const showModal = () => {
    setIsModalOpen(true);
  };
  const config = {
    public_key: "FLWPUBK-23da88c133fab96b8803a1fda5b98986-X",
    tx_ref: Date.now(),
    amount,
    currency: "NGN",
    payment_options: "card,mobilemoney,ussd",
    customer: {
      email: form.getFieldValue("email"),
      name: form.getFieldValue("name"),
    },
    customizations: {
      title: "JULIET EFEMENA FOUNDATION",
      description: "Donation to JULIET EFEMENA FOUNDATION ",
      logo: "https://github.com/itz-hassan/image/blob/main/logo.png?raw=true",
    },
  };
  
  const handleFlutterPayment = useFlutterwave(config);
  const onFinish = (values) => {
    setDetails(values);
    handleFlutterPayment({
      callback: (response) => {
        if (response.status === "successful") {
          notification.success({
            message: "Payment received successfuly. Thanks for your donation!",
          });
          const phone = response.customer ? response.customer.phone_number : "";
          const donoorTransactionData = {
            name: values.name,
            email: values.email,
            status: response.status,
            flw_ref: response.flw_ref,
            tx_ref: response.tx_ref,
            amount: values.amount,
            transaction_id: response.transaction_id,
            currency: response.currency,
            purpose: "donation",
          };
          console.log(donoorTransactionData);
          result.request(donoorTransactionData);
          form.resetFields();
          setIsModalOpen(false);
        }
        closePaymentModal(); // this will close the modal programmatically
      },
      onClose: (incomplete) => {
        console.log(incomplete);
        notification.warning({
          message: "Sorry payment failed, try again.",
        });
      },
    });
    console.log("Success:", values);
    // form.resetFields();
  };
  const handleOk = () => {
    // setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };
  const onChange = (value) => {
    console.log('changed', value);
  };
  return (
    <nav id="menu" className="navbar navbar-default navbar-fixed-top">
      <div className="container">
        <div className="navbar-header">
          <button
            type="button"
            className="navbar-toggle collapsed"
            data-toggle="collapse"
            data-target="#bs-example-navbar-collapse-1"
          >
            {" "}
            <span className="sr-only">Toggle navigation</span>{" "}
            <span className="icon-bar"></span>{" "}
            <span className="icon-bar"></span>{" "}
            <span className="icon-bar"></span>{" "}
          </button>
          <AppLink className="navbar-brand page-scroll" to="/">
            <img
              className="nav-logo"
              src="https://github.com/itz-hassan/image/blob/main/logo.png?raw=true"
              alt="JEF logo"
            />
          </AppLink>
        </div>

        <div
          className="collapse navbar-collapse"
          id="bs-example-navbar-collapse-1"
        >
          <ul className="nav navbar-nav navbar-right">
            {/* <li>
              <Link
                activeClass="active"
                to="features"
              spy={true}
                smooth={true}
                offset={-100}
                duration={500}
              >
                Features
              </Link>
            </li> */}
            <li>
              <Link
                className="link-item"
                activeclass="active"
                to="about"
                spy={true}
                smooth={true}
                offset={-100}
                duration={500}
              >
                About
              </Link>
            </li>
            <li className="dropdown">
              <Link
                className="link-item dropbtn"
                // activeClass="active"
                to="services"
                spy={true}
                smooth={true}
                offset={-100}
                duration={500}
              >
                Project
              </Link>
              <div className="dropdown-content">
                <Link
                  // activeClass="active"
                  to="currentProject"
                  spy={true}
                  smooth={true}
                  offset={-100}
                  duration={500}
                >
                  Current Project
                </Link>
                <Link
                  // activeClass="active"
                  to="currentProject"
                  spy={true}
                  smooth={true}
                  offset={-100}
                  duration={500}
                >
                  Post Project
                </Link>
                <Link
                  // activeClass="active"
                  to="currentProject"
                  spy={true}
                  smooth={true}
                  offset={-100}
                  duration={500}
                >
                  Future Project
                </Link>
              </div>
            </li>
            <li>
              <Link
                className="link-item"
                // activeClass="active"
                to="portfolio"
                spy={true}
                smooth={true}
                offset={-100}
                duration={500}
              >
                Gallery
              </Link>
            </li>
            {/* <li>
              <Link
                className="link-item"
                activeClass="active"
                to="testimonials"
              spy={true}
                smooth={true}
                offset={-100}
                duration={500}
              >
                Testimonials
              </Link>
            </li> */}
            {/* <li>
              <Link
                className="link-item"
                activeClass="active"
                to="team"
              spy={true}
                smooth={true}
                offset={-100}
                duration={500}
              >
                Team
              </Link>
            </li> */}
            <li>
              <Link
                className="link-item"
                // activeClass="active"
                to="contact"
                spy={true}
                smooth={true}
                offset={-100}
                duration={500}
              >
                Contact
              </Link>
            </li>
            <li>
              <AppLink
                className="link-item"
                // activeClass="active"
                to="/login"
                spy={true}
                smooth={true}
                offset={-100}
                duration={500}
              >
                Sign in
              </AppLink>
            </li>
            <li>
              <Link
                className="badge-btn"
                // activeClass="active"
                to="donate"
                spy={true}
                smooth={true}
                offset={-100}
                duration={500}
                onClick={showModal}
              >
                Donate Now
              </Link>
            </li>
          </ul>
        </div>
      </div>
      <Modal
        title="Donate Today!!!"
        open={isModalOpen}
        onOk={handleOk}
        // style={{ opacity: 0.9 }}
        onCancel={handleCancel}
      >
        <div>
          <Form
            name="Info"
            // initialValues={{}}
            onFinish={onFinish}
            form={form}
            layout={"vertical"}
            wrapperCol={{
              span: 16,
            }}
          >
            <Item
              label="Full Name"
              name="name"
              rules={[
                {
                  type: "string",
                  min: 3,
                  max: 255,
                },
              ]}
            >
              <Input />
            </Item>
            <Item
              label="Email"
              name="email"
              rules={[
                {
                  type: "email",
                  required: true,
                },
              ]}
            >
              <Input  />
            </Item>
          
    <CurrencyInput
className="ant-myinput"
id="amount"
  name="amount"
  prefix="₦"
  placeholder="Please enter an amount"
 
  decimalsLimit={2}
  onValueChange={(value, name) => setAmount(value)}
/>;
            
            <Item>
              <Button type="primary" htmlType="submit">
                Submit
              </Button>
            </Item>
          </Form>
        </div>
      </Modal>
    </nav>
  );
};
