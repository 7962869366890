import React from "react";
import "../css/about.css";

export const About = (props) => {
  return (
    <div id="about" data-aos="fade-up" data-aos-duration="2000">
      <div className="container">
        <div className="row-about">
          <div className="row=item">
            {" "}
            <img
              src="img/cac.jpeg"
              className="img-responsive"
              alt=""
              data-aos="fade-left"
            />
          </div>
          <div className="row-item">
            <div className="about-text">
              <h2>About Us</h2>
              <h3>THE BIRTH OF JULIET EFEMENA FOUNDATION</h3>
              {/* <p>{props.data ? props.data.paragraph : "loading..."}</p> */}
              <p>
                This foundation was given birth to by Lady Juliet Efemena
                Edugbo. Presently it has three (3) trustees namely, Mrs. Onome
                Mavis Eyetan and Ms. Sylvia Elohor Omoraro and I. It was
                incorporated on the 24th of February 2022 with the vision of
                making impact wherever there is need in the world. This passion
                has been in me for a long time - to reach out to so many
                vulnerable children/Orphans who need help to take them out of
                the street and in our own little way put smile on the faces of
                widows passing through stress.
              </p>
              <h3>Our aims and objectives amongst others are:</h3>
              <div className="list-style">
                <div>
                  <ul>
                    <li>
                      To create awareness among the people regarding AIDS, DRUGS
                      AND ALCOHOL ABUSE.
                    </li>
                    <li>
                      To provide educational empowerment through scholarships to
                      the less privileged children in both urban/rural areas.
                    </li>
                    <li>
                      To empower and support the poor widows/orphans and the
                      needy by providing help in areas of skills acquisition,
                      education, housing and health (medicals).
                    </li>
                    <li>
                      Providing social reliefs and religious activities in
                      communities, indigent Nigerians.
                    </li>
                    <li>
                      {" "}
                      Building and providing the essentials for the
                      orphanage/old people’s homes etc.
                    </li>
                    <li>
                      To provide social, educational, and economic empowerment
                      to women and children.
                    </li>
                    <li>
                      To safeguard the rights-freedom-privileges of the child
                      and provide for the well-being of children
                    </li>
                    <li>
                      To fight against exploitation, injustice and corruption if
                      found against any individual, class, creed, etc
                    </li>
                    <li>
                      To help and generate training program for self-employment
                      of women and educated unemployed people and to promote
                      adult education
                    </li>
                    <li>
                      To provide help to people suffering from natural
                      calamities such as floods, landslide/earthquakes, erosion,
                      windstorm, accidents (marine, land, sea and air) etc.{" "}
                      <a href="#more"> View more</a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
