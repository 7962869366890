import React, { useContext } from "react";
import { Route, Navigate, Outlet } from "react-router-dom";
import AuthContext from "../context/authContext/authContext";

const PrivateRoutes = ({ children }) => {
  const { isAuthenticated, loading, user } = useContext(AuthContext);
  localStorage.setItem("role", user && user.role);
  if (!isAuthenticated) {
    return <Navigate to="/login" replace />;
  }

  return children ? children : <Outlet />;
  // return isAuthenticated ? children : <Navigate to="/login" />;
};

export default PrivateRoutes;
