import React from "react";
import "../css/gallery.css";

export const Gallery = (props) => {
  return (
    <div id="portfolio" className="text-center">
      <div className="container">
        <div className="section-title">
          <h2>Gallery</h2>
          {/* <p>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit duis sed
            dapibus leonec.
          </p> */}
        </div>

        <div className="picture-grid">
          {props.data.GalleryVideos ? (
            <>
              <video
                style={{ position: "static" }}
                className="picture-grid-item"
                muted
                src={
                  props.data.GalleryVideos && props.data.GalleryVideos[0].vid
                }
                autoPlay
                loop
              ></video>
              <video
                style={{ position: "static" }}
                className="picture-grid-item"
                muted
                src={
                  props.data.GalleryVideos && props.data.GalleryVideos[1].vid
                }
                autoPlay
                loop
              ></video>
            </>
          ) : null}
          {props.data.Gallery
            ? props.data.Gallery.map((d, i) => (
                <img
                  key={i}
                  src={d.img}
                  className="picture-grid-item"
                  // style={{ display: "inline-block" }}
                />
              ))
            : "Loading"}
        </div>
        {/* <div className="row">
          <div className="portfolio-items">
            {props.data
              ? props.data.map((d, i) => (
                  <div key={i} className="col-sm-6 col-md-4 col-lg-4">
                    <div className="portfolio-item">
                      <div className="hover-bg">
                        <img src={d.img} className="img-responsive" alt={i} />
                      </div>
                    </div>
                  </div>
                ))
              : "Loading..."}
          </div>
        </div>
      </div> */}
      </div>
    </div>
  );
};
