import React, { useState, useEffect, useReducer, useLayoutEffect } from "react";
import { Link } from "react-router-dom";
import moment from "moment";
import "./catalog.css";
import {
  Pagination,
  Skeleton,
  Card,
  Typography,
  Space,
  Select,
  Descriptions,
} from "antd";
import config from "../../config/default.json";
import useApiGet from "../../hooks/useApiGet";

const { Meta } = Card;
const { Paragraph, Text } = Typography;
const { Option } = Select;
const { Item } = Descriptions;

function reducer(page, action) {
  return page + 1;
}

export default function ViewOrphans() {
  //   const { loadUser, user } = useContext(AuthContext);
  //   const [pageNumber, dispatch] = useReducer(reducer, 1);
  const [category, setCategory] = useState("");
  const [pageNumber, setPageNumber] = useState(1);
  const [pageSize, setPageSize] = useState(5);
  const [total, setTotal] = useState(0);

  const { data, request, loading } = useApiGet(
    `/api/beneficiaries/verified/list?role=${category}&pageNumber=${pageNumber}&pageSize=${pageSize}`
  );
  const {
    data: orphansCountFromDB,
    request: requestOrphansCount,
    // loading,
  } = useApiGet(`/api/beneficiaries/verified/count?role=${category}`);

  useLayoutEffect(() => {
    request();
    requestOrphansCount();
  }, [pageNumber, category]);

  useEffect(() => {
    setTotal(orphansCountFromDB);
  }, [data, orphansCountFromDB]);

  useEffect(() => {
    setTotal(data.length);
  }, [data]);

  console.log(data, category);
  const paginationNav = (
    <Pagination
      onChange={(page, pageSize) => setPageNumber(page)}
      size="small"
      total={total}
      pageSize={pageSize}
      hideOnSinglePage={true}
    />
  );

  return (
    <div>
      <Space direction="horizontal">
        <Select
          showSearch
          onChange={(e) => setCategory(e)}
          placeholder="Select Category"
        >
          <Option key={1} value="orphan">
            Orphan
          </Option>
          <Option key={2} value="widow">
            Widow
          </Option>
        </Select>
      </Space>
      <div
        // className="col-lg-3 col-sm-6"
        className="catalog-container"
      >
        {/* {paginationNav} */}
        {/* <Divider orientation="left">Course</Divider> */}
        {data && data.length > 0 ? (
          data.map((donee) => {
            const cardItem = (
              <Skeleton key={donee._id} loading={loading} active>
                <Card
                  className="donee-card"
                  hoverable
                  bordered={true}
                  loading={loading}
                  title={donee.name.toUpperCase()}
                  cover={
                    <img
                      alt={donee.name}
                      src={
                        donee.avatar.path === "assets/default.svg"
                          ? donee.avatar.path
                          : `${config.baseURL}api/beneficiaries/downloadProfilePic?fileName=${donee.avatar.path}`
                      }
                      style={{ width: 250, height: 200 }}
                    />
                  }
                >
                  <Meta title={donee.name.toUpperCase()} description={donee.story} />
                  <Descriptions column={1} size="small" title="User Info">
                    <Item label="Birthday">
                      {donee.role === "orphan"
                        ? moment(donee.dob).format("DD/MM/YYYY")
                        : ""}
                    </Item>
                    <Item label="Telephone">1810000000</Item>
                  </Descriptions>
                  {/* <Text
                        ellipsis={{
                          tooltip: course.courseDescription,
                          onEllipsis: (ellipsis) => {
                            console.log("Ellipsis changed:", ellipsis);
                          },
                          symbol: "more",
                        }}
                      >
                        {course.courseDescription}
                      </Text> */}
                  {/* <Link to={{ pathname: "/courseLMS", state: course._id }}>
                        Resume
                      </Link> */}
                  <Link
                    style={{ marginTop: 10 }}
                    className="btn btn-primary"
                    to={{ pathname: "/enrollment", state: donee._id }}
                  >
                    Sponsor Now
                  </Link>
                </Card>
              </Skeleton>
            );
            return category === ""
              ? cardItem
              : category && category === donee.role
              ? cardItem
              : null;
          })
        ) : !category ? (
          <Paragraph>Please select a course category</Paragraph>
        ) : (
          <Paragraph>No course available under this category</Paragraph>
        )}
        {category && data && data.length > 0 ? paginationNav : null}
      </div>
    </div>
  );
}
