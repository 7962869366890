import { useEffect, useState } from "react";
import "../css/header.css";

export const Header = (props) => {
  const [fadeProp, setfadeProp] = useState({ fade: "fade-in" });
  useEffect(() => {
    const timeOut = setInterval(() => {
      if (fadeProp.fade === "fade-in") {
        setfadeProp({ fade: "fade-out" });
      } else {
        setfadeProp({ fade: "fade-in" });
      }
    }, 12000);
    return () => clearInterval(timeOut);
  }, [fadeProp]);

  return (
    <header id="header" data-aos="fade-up">
      <div className="intro">
        {props.data && props.data.video && (
          <video
            id="background-video"
            // src="http://localhost:8000/api/events/downloadEventMedia/?fileName=.data/events/638787bcd99d15b94d4921e8/event_Manga bootcamp_0.mp4"
            // src={`http://localhost:8000/api/app_configs/downloadHomePageVid/?fileName=${props.data.video}`}
            src={
              "https://github.com/itz-hassan/image/blob/main/homePageVid.mp4?raw=true"
            }
            poster="../img/intro-bg.jpg"
            muted
            autoPlay
            loop
          ></video>
        )}
        <div className="overlay">
          <div className="container">
            <div className="row">
              <div className="col-md-8 col-md-offset-2 intro-text">
                <div className={fadeProp.fade}>
                  <h1 style={{ marginTop: 0, marginBottom: 5 }}>WELCOME TO</h1>
                  <h1 style={{ marginTop: 0 }}>JULIET EFEMENA FOUNDATION</h1>
                  {/* <p>{props.data ? props.data.paragraph : "Loading"}</p> */}
                </div>
                <br />
                <a
                  href="#contact"
                  className="btn btn-custom btn-lg page-scroll"
                >
                  Join us
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};
