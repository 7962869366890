import React, { useState, useEffect, useContext } from "react";
import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";
import {
  MenuFoldOutlined,
  MenuUnfoldOutlined,
  LogoutOutlined,
  UserOutlined,
} from "@ant-design/icons";
import { Layout, Menu, theme, Tooltip } from "antd";
import logo from "./pages/logo.png";
import { items } from "./layout/siderMenu";
import { Login } from "./pages";

import setAuthToken from "./utils/setAuthToken";
import AuthContext from "./context/authContext/authContext";
import {
  OrphanRegistration,
  DoneeReport,
  EditDonee,
} from "./modules/doneeRegistration";
import HomePage from "./components/Home";
import UnPrivateRoute from "./hocs/UnPrivateRoute";

// import { Dashboard } from "./modules/dashboard";
import ErrorBoundary from "./utils/ErrorBoundary";
import { AddEvent, EditEvent } from "./modules/events";
import { AddProject, ProjectReport, EditProject } from "./modules/project";
import { Sponsor, SponsorReport } from "./modules/sponsors";
import {
  EditStaffRegistered,
  StaffRegistration,
  StaffReport,
} from "./modules/staff";
import ViewOrphans from "./modules/dashboard/orphansList.component";
import "./App.css";
import EventReport from "./modules/events/eventReport";
import history from "./utils/history";
import { FounderProfile } from "./modules/founderProfile";
import { DonationReport } from "./modules/donoorTransaction";
import ViewDoneeDetail from "./components/viewDonee";
import ErrorPage from "./pages/ErrorPage";
import Unauthorized from "./pages/unauthorize";
import Dashboard from "./modules/dashboard/dashboard.component";
import PrivateRoutes from "./hocs/PrivateRoute";
import GalleryImage from "./modules/gallery/galleryImage";
import GalleryVideo from "./modules/gallery/galleryVideo";
import ChangePassword from "./pages/changePassword";
import ViewMore from "./components/viewMore";
import AdminRegister from "./pages/AdminRegister";
import { HelmetProvider } from "react-helmet-async";

if (localStorage.token) {
  setAuthToken(localStorage.token);
}
const { Header, Sider, Content } = Layout;

const App = (props) => {
  const [collapsed, setCollapsed] = useState(false);
  const [windowSize, setWindowSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });
  const {
    token: { colorBgContainer },
  } = theme.useToken();
  const [loading, setLoading] = useState(true);
  const { loadUser, user, isAuthenticated, logout, clearErrors } =
    useContext(AuthContext);
  // AOS.init();
  if (user) localStorage.setItem("role", user && user.role);
  const onLogout = () => {
    logout();
    clearErrors();
    localStorage.removeItem("role");
    history.push("/login");
  };
  useEffect(() => {
    loadUser();
    setLoading(false);
  }, [props]);
  useEffect(() => {
    function handleResize() {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    }
    if (windowSize.width <= 768) setCollapsed(true);
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  // console.log(windowSize);
  return (
    <HelmetProvider>
      <div>
        {/* <ErrorBoundary> */}
        {loading && user == null ? (
          <div id="preloader" />
        ) : (
          <Router>
            <Routes>
              <Route path="/" element={<HomePage />} />
              <Route path="viewMore/:id" element={<ViewMore />} />
            </Routes>
            <Layout>
              {isAuthenticated ? (
                <Sider
                  style={{
                    overflow: "auto",
                    height: "100vh",
                    position: "sticky",
                    left: 0,
                    top: 0,
                    bottom: 0,
                  }}
                  trigger={null}
                  collapsible
                  collapsed={collapsed}
                >
                  <div align="center">
                    {collapsed ? (
                      <Tooltip title="Juliet Efemena Foundation" placement="bottom">
                        <Link to="/dashboard">
                          <span className="brand-auth">JEF</span>
                        </Link>
                      </Tooltip>
                    ) : (
                      <Link to="/dashboard">
                        <img alt="" src={logo} className="img-auth" />
                      </Link>
                    )}
                  </div>
                  <Menu
                    theme="dark"
                    defaultSelectedKeys={["1w"]}
                    mode="inline"
                    items={items}
                  />
                </Sider>
              ) : null}
              <Layout className="site-layout">
                {isAuthenticated ? (
                  <nav
                    style={{
                      position: "sticky",
                      top: 0,
                      height: 75,
                      zIndex: 1,
                      width: "100%",
                      padding: 2,
                    }}
                    className="nav-auth"
                  >
                    {React.createElement(
                      collapsed ? MenuUnfoldOutlined : MenuFoldOutlined,
                      {
                        className: "trigger",
                        style: {
                          justifyContent: "flex-start",
                          color: "black",
                        },
                        onClick: () => setCollapsed(!collapsed),
                      }
                    )}
                    <ul>
                      <li>
                        <a onClick={onLogout}>
                          <Tooltip title="Sign out" placement="bottom">
                            <LogoutOutlined
                              style={{ fontSize: 30, paddingRight: 10 }}
                            />
                          </Tooltip>
                        </a>
                      </li>
                      <li>
                        <Tooltip title="Profile" placement="bottom">
                          <Link to="/changePassword">
                            <UserOutlined
                              style={{ fontSize: 30, paddingRight: 10 }}
                            />
                          </Link>
                        </Tooltip>
                      </li>
                      <li>
                        <a> Welcome, {user && user.name}</a>
                      </li>
                      {/* <li>
                        <a href="#services">Services</a>
                      </li>
                      <li>
                        <a href="#contact">Contact</a>
                      </li> */}
                    </ul>
                  </nav>
                ) : null}
                <Content
                  style={
                    isAuthenticated && {
                      margin: "16px 16px",
                      padding: 24,
                      minHeight: 280,
                      overflow: "initial",
                      background: colorBgContainer,
                    }
                  }
                >
                  <Routes>
                    {/* <Route path="/dashboard" element={<Dashboard />} /> */}
                    <Route
                      path="orphansList"
                      element={
                        <PrivateRoutes>
                          <ViewOrphans />
                        </PrivateRoutes>
                      }
                    />

                    <Route
                      path="/login"
                      element={
                        <UnPrivateRoute>
                          <Login />
                        </UnPrivateRoute>
                      }
                    />
                    <Route
                      path="/adminRegister"
                      element={
                        <UnPrivateRoute>
                          <AdminRegister />
                        </UnPrivateRoute>
                      }
                    />
                    <Route
                      path="/dashboard"
                      element={
                        <PrivateRoutes>
                          <Dashboard />
                        </PrivateRoutes>
                      }
                    />
                    <Route
                      path="/founderProfile"
                      element={
                        <PrivateRoutes>
                          <FounderProfile />
                        </PrivateRoutes>
                      }
                    />
                    <Route
                      path="/changePassword"
                      element={
                        <PrivateRoutes>
                          <ChangePassword />
                        </PrivateRoutes>
                      }
                    />
                    <Route
                      path="/galleryImage"
                      element={
                        <PrivateRoutes>
                          <GalleryImage />
                        </PrivateRoutes>
                      }
                    />
                    <Route
                      path="/galleryVideo"
                      element={
                        <PrivateRoutes>
                          <GalleryVideo />
                        </PrivateRoutes>
                      }
                    />
                    <Route
                      path="/doneeRegistration"
                      element={
                        <PrivateRoutes>
                          <OrphanRegistration />
                        </PrivateRoutes>
                      }
                    />
                    <Route
                      path="/doneeReport"
                      element={
                        <PrivateRoutes>
                          <DoneeReport />{" "}
                        </PrivateRoutes>
                      }
                    />
                    <Route
                      path="/editDonee"
                      element={
                        <PrivateRoutes>
                          <EditDonee />{" "}
                        </PrivateRoutes>
                      }
                    />
                    <Route
                      path="/addEvent"
                      element={
                        <PrivateRoutes>
                          {" "}
                          <AddEvent />{" "}
                        </PrivateRoutes>
                      }
                    />
                    <Route
                      path="/eventReport"
                      element={
                        <PrivateRoutes>
                          <EventReport />
                        </PrivateRoutes>
                      }
                    />
                    <Route
                      path="/editEvent"
                      element={
                        <PrivateRoutes>
                          <EditEvent />
                        </PrivateRoutes>
                      }
                    />
                    <Route
                      path="/addProject"
                      element={
                        <PrivateRoutes>
                          <AddProject />
                        </PrivateRoutes>
                      }
                    />
                    <Route
                      path="/projectReport"
                      element={
                        <PrivateRoutes>
                          <ProjectReport />
                        </PrivateRoutes>
                      }
                    />
                    <Route
                      path="/editProject"
                      element={
                        <PrivateRoutes>
                          <EditProject />
                        </PrivateRoutes>
                      }
                    />
                    <Route
                      path="/staffRegistration"
                      element={
                        <PrivateRoutes>
                          <StaffRegistration />
                        </PrivateRoutes>
                      }
                    />
                    <Route
                      path="/staffReport"
                      element={
                        <PrivateRoutes>
                          <StaffReport />
                        </PrivateRoutes>
                      }
                    />
                    <Route
                      path="/editStaff"
                      element={
                        <PrivateRoutes>
                          <EditStaffRegistered />
                        </PrivateRoutes>
                      }
                    />
                    <Route
                      path="/sponsorship"
                      element={
                        <PrivateRoutes>
                          <Sponsor />
                        </PrivateRoutes>
                      }
                    />
                    <Route
                      path="/sponsorshipList"
                      element={
                        <PrivateRoutes>
                          <SponsorReport />
                        </PrivateRoutes>
                      }
                    />
                    <Route
                      path="/donationReport"
                      element={
                        <PrivateRoutes>
                          <DonationReport />
                        </PrivateRoutes>
                      }
                    />
                    {isAuthenticated ? (
                      <Route exact path="*" element={<ErrorPage />}></Route>
                    ) : null}
                  </Routes>
                </Content>
              </Layout>
            </Layout>
          </Router>
        )}
        {/* </ErrorBoundary> */}
      </div>
    </HelmetProvider>
  );
};

export default App;
