export const numberFormatDollar = (value, decimal = 3) =>
  new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
    minimumFractionDigits: decimal,
  }).format(value);

export const numberFormatDigit = (value, decimal = 2) =>
  new Intl.NumberFormat("en-US", {
    style: "decimal",
    maximumFractionDigits: 0,
    // minimumFractionDigits: decimal,
  }).format(value);

export const numberFormatDecimal = (value, decimal) => {
  const d = decimal || 2;
  return new Intl.NumberFormat("en-US", {
    style: "decimal",
    maximumFractionDigits: d,
    // minimumFractionDigits: decimal,
  }).format(value);
};
