import "../css/testimonials.css";

export const Testimonials = (props) => {
  return (
    <div id="testimonials" data-aos="fade-right">
      <div className="container">
        <div className="section-title text-center">
          <h2>Founder's message</h2>
        </div>
        <div className="founder">
          <div className="founder-img">
            <img src={props.data && props.data.img} alt="" />
          </div>
          <div style={{ padding: 10 }}>
            <p style={{ fontSize: "2rem", fontStyle: "italic" }}>
              "Welcome to our foundation! We started this organization because
              we believe that everyone deserves access to quality education and
              opportunities to succeed. We are dedicated to supporting
              underprivileged orphans and widows by providing them with the
              resources they need to reach their full potential. We are so
              grateful for your support and hope that you will join us in our
              mission to make a positive impact in the world."
            </p>
            <div className="testimonial-meta"> - JULIET EFEMENA </div>
          </div>
        </div>
      </div>
    </div>
  );
};
