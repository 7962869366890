import React, { useEffect, useState, useContext } from "react";
//import { Link } from "react-router-dom";
import { Table, Input, Form, message } from "antd";
import { DeleteOutlined } from "@ant-design/icons";
//import EditIcon from "@material-ui/icons/Edit";
import moment from "moment";
import useApiGet from "../../hooks/useApiGet";
import { Link } from "react-router-dom";
import API from "../../config/API";

const { Search } = Input;

const EventReport = () => {
  const [fetchData, setFetchData] = useState(false);

  const [q, setQ] = useState("");
  const { data, request, loading } = useApiGet("/api/events/all");

  useEffect(() => {
    request();
  }, [fetchData]);

  const columns = [
    {
      title: "Title",
      dataIndex: "title",
      key: "_id",
    },
    {
      title: "Description",
      dataIndex: "description",
      key: "_id",
    },
    {
      title: " Start Date",
      dataIndex: "startDate",
      render: (el) => moment(el).format("DD/MM/YYY"),
      key: "_id",
    },
    {
      title: " End Date",
      dataIndex: "endDate",
      render: (el) => moment(el).format("DD/MM/YYYY"),
      key: "_id",
    },
    {
      title: "Action",
      dataIndex: "_id",
      key: "_id",
      align: "center",

      render: (id) => (
        <span>
          <Link to="/editEvent" state={{ id: id }}>
            Edit
          </Link>
          <DeleteOutlined
            onClick={() => {
              API.delete(`/api/events/${id}`)
                .then(() => {
                  request();
                  message.success({
                    content: "Successful",
                    duration: 2,
                  });
                })
                .catch((err) =>
                  message.error({
                    content: "Oops! something went wrong. Try again",
                  })
                );
            }}
          />
        </span>
      ),
    },
  ];

  const search = (data) => {
    return data.filter((record) => {
      return (
        record.description.toString().toLowerCase().indexOf(q.toLowerCase()) >
          -1 ||
        record.title.toString().toLowerCase().indexOf(q.toLowerCase()) > -1
      );
    });
  };

  return (
    <div>
      <div>
        <h6 align="center" style={{ marginLeft: "20px" }}>
          <h3>
            <em>JULIET EFEMENA FOUNDATION</em>
          </h3>
          <br />
        </h6>
        <h4 align="center">Event Report </h4>
        <br />
        <Search
          placeholder="Search"
          onChange={(e) => setQ(e.target.value)}
          enterButton
          style={{ width: 200 }}
        />
        <br />
        <Table
          columns={columns}
          bordered
          //  loading={loading}
          pagination
          dataSource={data}
          rowKey={(record) => record._id}
          size="small"
        />
      </div>
    </div>
  );
};
export default EventReport;
