import React, { useState, useLayoutEffect } from "react";
import "../css/orphan.css";
import "./shareBtn.css";
import useApiGet from "../hooks/useApiGet";
import { ShareAltOutlined, CheckOutlined } from "@ant-design/icons";
import config from "../config/default.json";

const ShareButton = ({ dataID }) => {
  const [shared, setShared] = useState(false);
  const { data, request, loading } = useApiGet(`/api/beneficiaries/verified/`);

  useLayoutEffect(() => {
    request();
  }, []);
  const beneficiary = data.find((d) => d._id === dataID);

  const handleClick = async () => {
    try {
      await navigator.share({
        title: `Sponsor ${beneficiary.name}`,
        text: `${beneficiary.name} ${beneficiary.story}`,
        url: `https://julietefemenafoundation.com/viewMore/${beneficiary._id}`,
      });
      setShared(true);
    } catch (error) {
      console.error("Error sharing:", error);
    }
  };
  console.log(beneficiary);
  return (
    <div className="text-block-r">
      {shared ? (
        <CheckOutlined />
      ) : (
        <div className="share-section">
          {beneficiary?.avatar && (
            <img
              src={
                beneficiary.avatar.path === "assets/default.svg"
                  ? beneficiary.avatar.path
                  : `${config.baseURL}api/beneficiaries/downloadProfilePic?fileName=${beneficiary.avatar.path}`
              }
              alt={beneficiary.name}
              className="beneficiary-thumbnail"
            />
          )}
          <span onClick={handleClick} className="text-item-right">
            <ShareAltOutlined />
          </span>
        </div>
      )}
    </div>
  );
};

export default ShareButton;
