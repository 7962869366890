import React from "react";
import { Link, useParams } from "react-router-dom";
import { useState, useEffect, useLayoutEffect } from "react";
import { useFlutterwave, closePaymentModal } from "flutterwave-react-v3";
import useApiPost from "../hooks/useApi";
import axios from "axios";
import { Navigation } from "./navigation";
import config from "../config/default.json";
import "../css/viewMore.css";
import useApiGet from "../hooks/useApiGet";
import {
  Divider,
  Layout,
  Form,
  notification,
  Modal,
  Empty,
  Input,
  InputNumber,
  Button,
} from "antd";
import moment from "moment";
import { Helmet } from "react-helmet"; //
const { Header } = Layout;

const { Item } = Form;
function ViewMore() {
  const [landingPageData, setLandingPageData] = useState({});
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [showdetails, setShowDetails] = useState([]);
  const [sponsorID, setSponsorID] = useState("");
  const [amount, setAmount] = useState(0);
  const [form] = Form.useForm();
  const { data, request, loading } = useApiGet(`/api/beneficiaries/verified/`);
  const result = useApiPost("/api/donations/");
  const showModalPay = () => {
    setIsModalOpen(true);
  };
  const handleOk = () => {
    // setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  useLayoutEffect(() => {
    request();
    axios
      .get(`${config.baseURL}api/app_configs/`)
      .then((res) => setLandingPageData(res.data))
      .catch((err) => console.log(err));
    // setLandingPageData(JsonData);
  }, []);
  const { id } = useParams();
  const beneficiary = data.find((d) => d._id === id);
  const sponsorPayment = (id) => {
    setSponsorID(id);
    showModalPay();
  };

  const payment_config = {
    public_key: "FLWPUBK-23da88c133fab96b8803a1fda5b98986-X",
    tx_ref: Date.now(),
    amount,
    currency: "NGN",
    payment_options: "card,mobilemoney,ussd",
    customer: {
      email: form.getFieldValue("email"),
      name: form.getFieldValue("name"),
    },
    customizations: {
      title: "JULIET EFEMENA FOUNDATION",
      description: `Sponsorship to ${form.getFieldValue("name")}`,
      logo: "https://github.com/itz-hassan/image/blob/main/logo.png?raw=true",
    },
  };
  const handleFlutterPayment = useFlutterwave(payment_config);

  const onFinish = (values) => {
    handleFlutterPayment({
      callback: (response) => {
        if (response.status === "successful") {
          notification.success({
            message: `Payment received successfuly. Thanks for sponsoring ${
              beneficiary && beneficiary.name
            }`,
          });
          const transactionDetail = {
            name: response.customer.name,
            email: response.customer.email,
            phone: response.customer.phone_number
              ? response.customer.phone_number
              : "",
            status: response.status,
            transaction_id: response.transaction_id,
            tx_ref: response.tx_ref,
            currency: response.currency,
            amount: response.amount,
            flw_ref: response.flw_ref,
            doneeID: beneficiary._id,
            doneeName: beneficiary.name,
            role: beneficiary.role,
            purpose: "sponsorship",
          };
          result.request(transactionDetail);
          form.resetFields();
          setIsModalOpen(false);
        }
        closePaymentModal(); // this will close the modal programmatically
      },
      onClose: (incomplete) => {
        // console.log(incomplete);
        notification.warning({
          message: "Sorry payment failed, try again.",
        });
      },
    });
  };

  return (
    <div>
      <Helmet>
        <title>
          Sponsor {beneficiary?.name || "a Beneficiary"} - Juliet Efemena Foundation
        </title>
        <meta
          name="description"
          content={
            beneficiary?.story ||
            "Help sponsor someone in need and make a difference in their life."
          }
        />
        <meta
          property="og:title"
          content={`Sponsor ${beneficiary?.name || "a Beneficiary"}`}
        />
        <meta
          property="og:description"
          content={
            beneficiary?.story ||
            "Support an orphan or widow with your kind gesture."
          }
        />
        <meta
          property="og:image"
          content={
            beneficiary?.avatar?.path
              ? `${config.baseURL}api/beneficiaries/downloadProfilePic?fileName=${beneficiary.avatar.path}`
              : "https://w7.pngwing.com/pngs/349/268/png-transparent-avatar-boy-cartoon-comic-comic-characters-faceless-human-male-man-people-thumbnail.png"
          }
        />
        <meta
          property="og:url"
          content={`https://julietefemenafoundation.com/viewMore/${id}`}
        />
        <meta property="og:type" content="website" />
      </Helmet>
      <Navigation data={landingPageData.data} />
      <Modal
        title="Donate Today!!!"
        open={isModalOpen}
        onOk={handleOk}
        style={{ opacity: 0.98 }}
        onCancel={handleCancel}
      >
        {" "}
        <br />
        <p
          style={{
            fontStyle: "italic",
            marginTop: 10,
          }}
        >
          Change a life today. Sponsor an orphan child today.
        </p>
        <div>
          <Form
            name="Info"
            initialValues={{}}
            onFinish={onFinish}
            form={form}
            layout={"vertical"}
          >
            <Item
              label="Full Name of sponsorer"
              name="name"
              rules={[
                {
                  type: "string",
                  min: 3,
                  max: 255,
                },
              ]}
            >
              <Input />
            </Item>
            <Item
              label="Email"
              name="email"
              rules={[
                {
                  type: "email",
                  required: true,
                },
              ]}
            >
              <Input />
            </Item>
            <Item
              label="Amount"
              name="amount"
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <InputNumber
                formatter={(value) =>
                  `₦ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                }
                parser={(value) => value.replace(/\₦\s?|(,*)/g, "")}
                min={100}
                style={{ width: "40%" }}
                onChange={(value) => setAmount(value)}
              />
            </Item>
            <Item>
              <Button type="primary" htmlType="submit">
                Pay Now
              </Button>
            </Item>
          </Form>
        </div>
      </Modal>
      <br />

      {beneficiary ? (
        <div>
          {" "}
          <div className="section-title">
            <h2 align="center">Beneficiary Details</h2>
          </div>
          <div className="container-more">
            <div className="b-img more-item">
              <img
                alt=" "
                src={
                  beneficiary.avatar.path === "assets/default.svg"
                    ? beneficiary.avatar.path
                    : `${config.baseURL}api/beneficiaries/downloadProfilePic?fileName=${beneficiary.avatar.path}`
                }
              />
            </div>
            <div className="content-more more-item container bg-light p-4 shadow rounded">
              <h3 className="text-primary border-bottom pb-2">Personal Details</h3>
              <div className="row mb-3">
                <div className="col-md-6">
                  {beneficiary.name && (
                    <p>
                      <strong>Full Name:</strong> {beneficiary.name}
                    </p>
                  )}
                  {beneficiary.email && (
                    <p>
                      <strong>Email:</strong> {beneficiary.email}
                    </p>
                  )}
                  {beneficiary.dob && (
                    <p>
                      <strong>Age:</strong>{" "}
                      {moment().diff(beneficiary.dob, "years", false)}
                    </p>
                  )}
                </div>
                <div className="col-md-6">
                  {beneficiary.gender && (
                    <p>
                      <strong>Gender:</strong> {beneficiary.gender}
                    </p>
                  )}
                  {beneficiary.phone && (
                    <p>
                      <strong>Phone:</strong> {beneficiary.phone}
                    </p>
                  )}
                  {beneficiary.dob && (
                    <p>
                      <strong>DOB:</strong>{" "}
                      {moment(beneficiary.dob).format("YYYY-MM-DD")}
                    </p>
                  )}
                </div>
              </div>

              <h4 className="text-secondary border-bottom pb-2">Skills & History</h4>
              <div className="row mb-3">
                <div className="col-md-6">
                  {beneficiary.role && (
                    <p>
                      <strong>Category:</strong> {beneficiary.role}
                    </p>
                  )}
                  <p>
                    <strong>Skills:</strong>{" "}
                    {beneficiary.skills.length > 0
                      ? beneficiary.skills.map((skill, idx) => (
                          <span key={idx} className="badge bg-primary me-1">
                            {skill}
                          </span>
                        ))
                      : "None"}
                  </p>
                </div>
                <div className="col-md-6">
                  <p>
                    <strong>Employment History:</strong>{" "}
                    {beneficiary.employment_history.length > 0
                      ? beneficiary.employment_history.map((history, idx) => (
                          <span key={idx} className="badge bg-success me-1">
                            {history}
                          </span>
                        ))
                      : "None"}
                  </p>
                  {beneficiary.story && (
                    <p>
                      <strong>Story:</strong> {beneficiary.story}
                    </p>
                  )}
                </div>
              </div>

              <h4 className="text-secondary border-bottom pb-2">Address</h4>
              {beneficiary.nationality?.residentialAddress && (
                <p>
                  <strong>Address:</strong>{" "}
                  {`${beneficiary.nationality.residentialAddress.houseNo}, 
      ${beneficiary.nationality.residentialAddress.street}, 
      ${beneficiary.nationality.residentialAddress.state}, 
      ${beneficiary.nationality.residentialAddress.country}`}
                </p>
              )}
              <p>
                {beneficiary.nationality?.nationality && (
                  <span>
                    <strong>Nationality:</strong>{" "}
                    {beneficiary.nationality.nationality}{" "}
                  </span>
                )}
                {beneficiary.nationality?.localGovernmentArea && (
                  <span>
                    <strong>LGA:</strong>{" "}
                    {beneficiary.nationality.localGovernmentArea}
                  </span>
                )}
              </p>

              <h4 className="text-secondary border-bottom pb-2">Bank Details</h4>
              {beneficiary.bank_details?.acct_no && (
                <p>
                  <strong>Account Number:</strong> {beneficiary.bank_details.acct_no}
                </p>
              )}
              {beneficiary.bank_details?.acct_name && (
                <p>
                  <strong>Account Name:</strong> {beneficiary.bank_details.acct_name}
                </p>
              )}
              {beneficiary.bank_details?.bank && (
                <p>
                  <strong>Bank Name:</strong> {beneficiary.bank_details.bank}
                </p>
              )}

              <div className="text-center mt-4">
                <button
                  onClick={() => sponsorPayment(beneficiary._id)}
                  className="btn btn-primary btn-lg"
                >
                  Sponsor {beneficiary.gender === "male" ? "Him" : "Her"} Now
                </button>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <Empty />
      )}
    </div>
  );
}

export default ViewMore;
