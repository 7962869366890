import { useState } from "react";
import { notification } from "antd";
import API from "../config/API";

const useApiGet = (endPoint) => {
  const [data, setData] = useState([]);
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);

  const request = (...args) => {
    setLoading(true);
    API.get(endPoint, ...args)
      .then((res) => {
        setData(res.data);
        setLoading(false);
      })
      .catch((err) => {
        setError(err);
        setLoading(false);
        console.log(err);
        errorMsg();
      });
  };

  const errorMsg = () => {
    notification.error({
      message: "Connection Error",
      description: error ? error : "Oops something went wrong !!!",
      duration: 10,
    });
  };
  return { data, loading, request };
};
export default useApiGet;
