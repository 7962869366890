import React from "react";
import { Input } from "antd";

export default function AppInput({ width = "100%", disabled, ...otherProps }) {
  return (
    <div>
      <Input width={width} {...otherProps} disabled={disabled} />
    </div>
  );
}
