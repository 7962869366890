import React, { useEffect, useState } from "react";
// import AppDatePicker from "../components/AppDatePicker";
import { Select, Option } from "../../components/AppSelect";
import AppSubmitButton from "../../components/AppButton";
import AppTextArea from "../../components/AppTextArea";
import useApiPost from "../../hooks/useApi";
import {
  Form,
  message,
  Button,
  Upload,
  Divider,
  DatePicker,
  InputNumber,
} from "antd";
import { UploadOutlined } from "@ant-design/icons";
import AppInput from "../../components/AppInput";
import { Link } from "react-router-dom";

const { Item: Field } = Form;
const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 12 },
};
const tailLayout = {
  wrapperCol: { offset: 8, span: 12 },
};

const OrphanRegistration = () => {
  const [form] = Form.useForm();
  const [isVisible, setIsVisible] = useState(false);

  const result = useApiPost("/api/beneficiaries/");
  const [disable, setDisable] = useState(false);

  const props = {
    type: "drag",
    beforeUpload: (file) => {
      const isJPEG = file.type === "image/jpeg";
      if (!isJPEG) {
        message.error(`${file.name} is not a jpeg file`);
      }
      return isJPEG || Upload.LIST_IGNORE;
    },
    maxCount: 1,
    multiple: false,
    // onChange: (e) => setFiles(e.file.originFileObj),
    customRequest: ({ file, onSuccess }) => {
      setTimeout(() => {
        onSuccess("ok");
      }, 0);
    },
  };

  // const prevStep = () => setStep(step - 1);

  // const nextStep = () => setStep(step + 1);

  const handleSubmit = (values) => {
    const formData = new FormData();

    if (
      values.ID_image &&
      values.ID_image.file &&
      values.ID_image.file.originFileObj
    ) {
      formData.append("ID_image", values.ID_image.file.originFileObj);
    }
    if (
      values.avatar &&
      values.avatar.file &&
      values.avatar.file.originFileObj
    ) {
      formData.append("avatar", values.avatar.file.originFileObj);
    }

    if (values.skills) {
      for (let i = 0; i < values.skills.length; i++) {
        formData.append("skills", values.skills[i]);
      }
    }

    if (values.employmentHistory) {
      for (let i = 0; i < values.employmentHistory.length; i++) {
        formData.append("employment_history", values.employmentHistory[i]);
      }
    }

    formData.append("first_name", values.first_name);
    formData.append("last_name", values.last_name);
    formData.append("levelOfEduc", values.levelOfEduc);
    formData.append("email", values.email);
    formData.append("phone", values.phone);
    formData.append("dob", values.dob);
    formData.append("role", values.role);
    formData.append("gender", values.gender);
    formData.append("idType", values.idType);
    formData.append("id_no", values.id_no);
    formData.append("nationality", values.nationality);
    formData.append("stateOfOrigin", values.stateOfOrigin);
    formData.append("localGovernmentArea", values.localGovernmentArea);
    formData.append("acct_no", values.accountNo);
    formData.append("acct_name", values.accountName);
    formData.append("bank", values.bankName);
    formData.append("houseNo", values.houseNo);
    formData.append("street", values.street);
    formData.append("state", values.state);
    formData.append("country", values.country);
    formData.append("story", values.story);

    result.request(formData);
    form.resetFields();
  };

  return (
    <div>
      <h4>Beneficiary Registration</h4>
      <Form
        {...layout}
        scrollToFirstError
        name="Donee-component"
        form={form}
        // validateMessages={validateMessages}
        onFinish={handleSubmit}
      >
        <Field
          name="role"
          label="Select donee category"
          // className="col-md-3 m-3"
          tooltip="This is a required field"
          rules={[
            {
              required: true,
              type: "string",
            },
          ]}
        >
          <Select
            onChange={(e) => setIsVisible(true)}
            style={{ width: 250 }}
            placeholder="Select"
          >
            <Option key={1} value="orphan">
              Orphan
            </Option>
            <Option key={2} value="widow">
              Widow
            </Option>
            <Option key={3} value="less-privileged">
              Less-privileged
            </Option>
          </Select>
        </Field>
        {isVisible ? (
          <>
            <div id="details">
              <Divider plain>Details</Divider>
              <Field
                name="first_name"
                label="First Name"
                // className="col-md-3 m-3"
                tooltip="This is a required field"
                rules={[{ required: true, type: "text" }]}
              >
                <AppInput style={{ width: 250 }} />
              </Field>
              <Field
                name="last_name"
                label="Last Name"
                // className="col-md-3 m-3"
                tooltip="This is a required field"
                rules={[{ required: true, type: "text" }]}
              >
                <AppInput style={{ width: 250 }} />
              </Field>
              <Field
                name="email"
                label="Email"
                // className="col-md-3 m-3"
                tooltip="This is a required field"
                rules={[{ required: true, type: "text" }]}
              >
                <AppInput style={{ width: 250 }} />
              </Field>
              <Field
                name="phone"
                label="Phone"
                // className="col-md-3 m-3"
                tooltip="This is a required field"
                rules={[{ required: false, type: "text" }]}
              >
                <AppInput style={{ width: 250 }} />
              </Field>
              <Field
                name="gender"
                label="Select Gender"
                // className="col-md-3 m-3"
                tooltip="This is a required field"
                rules={[
                  {
                    required: true,
                    type: "string",
                  },
                ]}
              >
                <Select style={{ width: 250 }} placeholder="Select">
                  <Option key={1} value="male">
                    Male
                  </Option>
                  <Option key={2} value="female">
                    Female
                  </Option>
                </Select>
              </Field>
              <Field
                name="dob"
                label="Date of Birth"
                // className="col-md-3 m-3"
                tooltip="This is a required field"
                rules={[
                  {
                    required: true,
                    type: "date",
                  },
                ]}
              >
                <DatePicker
                  picker="day"
                  onChange={(e) => e}
                  dateFormat="dd/MM/yyyy"
                  placeholderText="Enter Date"
                />
              </Field>
            </div>

            <div id="nationality">
              <Divider plain>Nationality</Divider>
              <Field
                name="nationality"
                label="Nationality"
                // className="col-md-3 m-3"
                tooltip="This is a required field"
                rules={[{ type: "text" }]}
              >
                <AppInput style={{ width: 250 }} />
              </Field>
              <Field
                name="stateOfOrigin"
                label="State Of Origin"
                // className="col-md-3 m-3"
                tooltip="This is a required field"
                rules={[{ type: "text" }]}
              >
                <AppInput style={{ width: 250 }} />
              </Field>
              <Field
                name="localGovernmentArea"
                label="LGA"
                // className="col-md-3 m-3"
                tooltip="This is a required field"
                rules={[{ type: "text" }]}
              >
                <AppInput style={{ width: 250 }} />
              </Field>
            </div>

            <div id="residential">
              <Divider>Residential Address (Optional)</Divider>
              <Field
                name="houseNo"
                label="House No"
                // className="col-md-3 m-3"
                tooltip="This is a required field"
                rules={[{ type: "text" }]}
              >
                <AppInput style={{ width: 250 }} />
              </Field>
              <Field
                name="street"
                label="Street"
                // className="col-md-3 m-3"
                tooltip="This is a required field"
                rules={[{ type: "text" }]}
              >
                <AppInput style={{ width: 250 }} />
              </Field>
              <Field
                name="state"
                label="State"
                // className="col-md-3 m-3"
                tooltip="This is a required field"
                rules={[{ type: "text" }]}
              >
                <AppInput style={{ width: 250 }} />
              </Field>
              <Field
                name="country"
                label="Country"
                // className="col-md-3 m-3"
                tooltip="This is a required field"
                rules={[{ type: "text" }]}
              >
                <AppInput style={{ width: 250 }} />
              </Field>
            </div>

            <div id="acct">
              <Divider>Account Details (Optional)</Divider>
              <Field
                name="accountNo"
                label="Accont Number"
                // className="col-md-3 m-3"
                tooltip="This is a required field"
                rules={[{ type: "number" }]}
              >
                <InputNumber style={{ width: 250 }} />
              </Field>
              <Field
                name="accountName"
                label="Account Name"
                // className="col-md-3 m-3"
                tooltip="This is a required field"
                rules={[{ type: "text" }]}
              >
                <AppInput style={{ width: 250 }} />
              </Field>
              <Field
                name="bankName"
                label="Bank Name"
                // className="col-md-3 m-3"
                tooltip="This is a required field"
                rules={[{ type: "text" }]}
              >
                <AppInput style={{ width: 250 }} />
              </Field>
            </div>

            {/* <Field
              name="password"
              label="Password"
              // className="col-md-3 m-3"
              tooltip="This is a required field"
              rules={[{ required: true, type: "text" }]}
            >
              <AppInput style={{ width: 250 }} />
            </Field> */}

            <div id="educ">
              <Divider>Educational Details</Divider>
              <Field
                name="levelOfEduc"
                label="Level Of Education"
                // className="col-md-3 m-3"
                tooltip="This is a required field"
                rules={[{ required: true, type: "text" }]}
              >
                <Select style={{ width: 250 }} placeholder="Select">
                  <Option key={0} value="Basic/Primary/Elementary">
                    Basic/Primary/Elementary school
                  </Option>
                  <Option key={1} value="JSCE/Middle_school">
                    JSCE/Middle school
                  </Option>
                  <Option key={2} value="SSCE/High_school">
                    SSCE/High school
                  </Option>
                  <Option key={3} value="Diploma">
                    Diploma
                  </Option>
                  <Option key={4} value="undergraduate">
                    Undergraduate
                  </Option>
                  <Option key={5} value="graduate">
                    Post Grad
                  </Option>
                </Select>
              </Field>
              {/* <Field
                name="Skills"
                label="Skills"
                // className="col-md-3 m-3"
                tooltip="This is a required field"
                rules={[{ required: false, type: "text" }]}
              >
                <AppInput style={{ width: 250 }} />
              </Field> */}

              <Field
                name="skills"
                label="Skills"
                tooltip="Prev. job titles"
                rules={[{ required: false }]}
              >
                <Select
                  showSearch
                  placeholder="Hard and soft skills"
                  mode="tags"
                  allowClear
                  style={{ width: "100%" }}
                  disabled={disable}
                ></Select>
              </Field>
              <Field
                name="employmentHistory"
                label="Employment History"
                tooltip="Prev. job titles"
                rules={[{ required: false }]}
              >
                <Select
                  showSearch
                  placeholder="Job Titles"
                  mode="tags"
                  allowClear
                  style={{ width: "100%" }}
                  disabled={disable}
                ></Select>
              </Field>
              {/* <Field
                name="employmentHistory"
                label="Employment History"
                // className="col-md-3 m-3"
                tooltip="Prev. job titles"
                rules={[{ required: true, type: "text" }]}
              >
                <AppInput style={{ width: 250 }} />
              </Field> */}
            </div>

            <div id="Identity">
              <Divider>Identity Details</Divider>
              <Field
                name="idType"
                label="Select ID type"
                // className="col-md-3 m-3"
                tooltip="This is a required field"
                rules={[
                  {
                    required: false,
                    type: "string",
                  },
                ]}
              >
                <Select
                  onChange={(e) => e}
                  style={{ width: 250 }}
                  placeholder="Select"
                >
                  <Option key={1} value="Driver License">
                    Driver License
                  </Option>
                  <Option key={2} value="National Identity NIN">
                    National Identity NIN
                  </Option>
                  <Option key={3} value="International Passport">
                    International Passport
                  </Option>
                  <Option key={4} value="Voters Card">
                    Voters Card
                  </Option>
                  <Option key={5} value="Birth Certificate">
                    Birth Certificate (for Orphans Only)
                  </Option>
                </Select>
              </Field>
              <Field
                name="id_no"
                label="Enter ID No"
                // className="col-md-3 m-3"
                tooltip="This is a required field"
                rules={[{ type: "text" }]}
              >
                <AppInput style={{ width: 250 }} />
              </Field>
              <Field
                name="ID_image"
                label="ID Image"
                tooltip="Only Jpeg files are acceptable"
                rules={[{ required: false }]}
              >
                <Upload
                  getValueFromEvent={({ file }) => file.originFileObj}
                  {...props}
                >
                  <Button disabled={disable} icon={<UploadOutlined />}>
                    Click to Upload
                  </Button>
                </Upload>
              </Field>
              <Field
                name="avatar"
                label="Profile Image"
                tooltip="Only Jpeg files are acceptable"
                rules={[{ required: false }]}
              >
                <Upload
                  getValueFromEvent={({ file }) => file.originFileObj}
                  {...props}
                >
                  <Button disabled={disable} icon={<UploadOutlined />}>
                    Click to Upload
                  </Button>
                </Upload>
              </Field>
              <Field
                name="story"
                label="Tell your story"
                // className="col-md-3 m-3"
                tooltip="This is a required field"
                rules={[{ required: true, type: "text" }]}
              >
                <AppTextArea rows={10} style={{ width: "100%" }} />
              </Field>
            </div>

            <Field {...tailLayout}>
              <AppSubmitButton disabled={disable} htmlType="submit" />
            </Field>
            <Link to="/doneeReport">
              <p>View Donee Report?</p>
            </Link>
          </>
        ) : (
          ""
        )}
      </Form>
    </div>
  );
};

export default OrphanRegistration;
