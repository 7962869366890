import React, { useEffect, useState, useContext } from "react";
import { Link } from "react-router-dom";
import { Form, Table, Input, Tooltip } from "antd";
import moment from "moment";
import useApiGet from "../../hooks/useApiGet";
import { Select, Option } from "../../components/AppSelect";
import { numberFormatDigit } from "../../hooks/AppNumberFormat";
const { Search } = Input;
//const { Option } = Select;
const { Item: Field } = Form;
const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 12 },
};
const tailLayout = {
  wrapperCol: { offset: 8, span: 12 },
};

const columns = [
  {
    title: "Name",
    dataIndex: "name",
    render: (el) => el.toUpperCase(),
    sorter: (a, b) => a.name.localeCompare(b.name),
    key: "_id",
    fixed: "left",
  },
  {
    title: "Email",
    dataIndex: "email",
    key: "_id",
    fixed: "left",
  },
  {
    title: "Phone",
    dataIndex: "phone",
    key: "_id",
    fixed: "left",
  },
  {
    title: "Currency",
    dataIndex: "currency",
    key: "_id",
  },
  {
    title: "Amount",
    dataIndex: "amount",
    render: (el) => numberFormatDigit(el),
    key: "_id",
  },
  {
    title: "Status",
    dataIndex: "status",
    key: "_id",
  },
  {
    title: "Purpose",
    dataIndex: "purpose",
    onFilter: (value, record) => record.purpose.toString().indexOf(value) === 0,

    filters: [
      {
        text: "Donation",
        value: "donation",
      },
      {
        text: "Sponsorship",
        value: "sponsorship",
      },
    ],
    key: "_id",
  },
  {
    title: "Beneficiary Name",
    dataIndex: "doneeName",
    key: "_id",
  },
  {
    title: "Role",
    dataIndex: "role",
    onFilter: (value, record) => record.role.toString().indexOf(value) === 0,

    filters: [
      {
        text: "Orphan",
        value: "orphan",
      },
      {
        text: "Widow",
        value: "widow",
      },
    ],

    key: "_id",
  },
  {
    title: "Date",
    dataIndex: "paymentDate",
    render: (el) => moment(el).format("DD-MMM-yyyy"),
    key: "_id",
  },
  {
    title: "Reference",
    dataIndex: "tx_ref",
    key: "_id",
  },
  {
    title: "Flutter wave Reference",
    dataIndex: "flw_ref",
    key: "_id",
  },
  {
    title: "Transaction ID",
    dataIndex: "transaction_id",
    key: "_id",
  },
];

const DonationReport = () => {
  const [form] = Form.useForm();
  const [isVisible, setIsVisible] = useState(false);
  const [fetchData, setFetchData] = useState(false);
  const [doneeData, setDoneeData] = useState([]);
  const [role, setRole] = useState("");
  const [q, setQ] = useState("");
  const { data, request, loading } = useApiGet("/api/donations/");

  useEffect(() => {
    request();
  }, [fetchData]);

  const search = (data) => {
    return data.filter((record) => {
      return (
        record.name.toString().toLowerCase().indexOf(q.toLowerCase()) > -1 ||
        record.email.toString().toLowerCase().indexOf(q.toLowerCase()) > -1
      );
    });
  };

  return (
    <div>
      <div>
        <h6 align="center" style={{ marginLeft: "20px" }}>
          <span>
            <img
              className="nav-logo"
              src={data ? data.url : ""}
              alt="JEF logo"
            />
          </span>{" "}
          <h3>
            <em>JULIET EFEMENA FOUNDATION</em>
          </h3>
          <br />
        </h6>
        <h4 align="center"> Donation Report </h4>
        <br />{" "}
        <Search
          placeholder="Search"
          onChange={(e) => setQ(e.target.value)}
          enterButton
          style={{ width: 200 }}
        />
        <br />
        <Table
          //  scroll={{ y: 2300, x: 2300 }}
          columns={columns}
          bordered
          loading={loading}
          pagination
          dataSource={search(data)}
          rowKey={(record) => record._id}
          size="small"
        />
      </div>
    </div>
  );
};
export default DonationReport;
