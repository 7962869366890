import { useState, useEffect, useLayoutEffect } from "react";
import axios from "axios";
import config from "../config/default.json";

import { Navigation } from "./navigation";
import { Header } from "./header";
import { About } from "./about";
import { Project } from "./project";

import { Orphans } from "./orphans";
import { Gallery } from "./gallery";
import { Testimonials } from "./testimonials";
import { MoreABout } from "./moreAbout";
import { Contact } from "./contact";
import { Widow } from "./widow";
import { Less_privileged } from "./less_privileged";
import { Stat } from "./impact";
// import JsonData from "./data/data.json";
// import Donate from "./donate";
// import TestSlider from "./TestSlider";
// import AOS from "aos";
// import "aos/dist/aos.css";

const HomePage = () => {
  const [landingPageData, setLandingPageData] = useState({});

  useLayoutEffect(() => {
    axios
      .get(`${config.baseURL}api/app_configs/`)
      .then((res) => setLandingPageData(res.data))
      .catch((err) => console.log(err));
    // setLandingPageData(JsonData);
  }, []);
  // useEffect(() => {
  //   AOS.init({ duration: 1200 });
  //   AOS.refresh();
  // }, []);
  return (
    <div>
      <Navigation data={landingPageData.Logo} />
      <Header data={landingPageData.Header} />
      <Orphans data={landingPageData} />
      <Widow data={landingPageData.Widows} />
      <Less_privileged data={landingPageData} />
      <Project data={landingPageData.Services} />
      <Gallery data={landingPageData} />
      <Testimonials data={landingPageData.founderMsg} />
      <About data={landingPageData.About} />
      <MoreABout data={landingPageData.Team} />
      <Stat data={landingPageData.Header} title="Our Impact" />
      <Contact data={landingPageData.Contact} />
      {/* <Donate /> */}
    </div>
  );
};

export default HomePage;
