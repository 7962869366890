import React, { useState, useEffect, useLayoutEffect } from "react";
import {
  PlusOutlined,
  CaretDownOutlined,
  MinusOutlined,
} from "@ant-design/icons";
import { Link } from "react-router-dom";
import {
  Pagination,
  Modal,
  Empty,
  Form,
  notification,
  Input,
  Button,
  InputNumber,
} from "antd";
import "../css/orphan.css";
import config from "../config/default.json";
import useApiGet from "../hooks/useApiGet";
import moment from "moment";
import { useFlutterwave, closePaymentModal } from "flutterwave-react-v3";
import useApiPost from "../hooks/useApi";
import ShareButton from "./shareButton";
import CurrencyInput from 'react-currency-input-field';

const { Item } = Form;
export const Widow = (props) => {
  const [category, setCategory] = useState("");
  const [pageNumber, setPageNumber] = useState(1);
  const [pageSize, setPageSize] = useState(5);
  const [total, setTotal] = useState(0);
  const [showdetails, setShowDetails] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [sponsorID, setSponsorID] = useState("");
  const [amount, setAmount] = useState(0);
  const [form] = Form.useForm();

  const showModalPay = () => {
    setIsModalOpen(true);
  };
  const handleOk = () => {
    // setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const { data, request, loading } = useApiGet(`/api/beneficiaries/verified/`);
  const {
    data: widowsCountFromDB,
    request: requestWidowCount,
    loadingB,
  } = useApiGet(`/api/beneficiaries/verified/count?role=${category}`);
  const result = useApiPost("/api/donations/");

  useLayoutEffect(() => {
    request();
    requestWidowCount();
  }, [pageNumber, category]);

  useEffect(() => {
    setTotal(widowsCountFromDB);
  }, [data, widowsCountFromDB]);

  useEffect(() => {
    setTotal(data.length);
  }, [data]);

  useEffect(() => {
    const arr = [];
    for (let i = 0; i < widowsCountFromDB; i++) {
      const e = i;
      return arr.push[false];
    }
    setShowDetails(arr);
  }, [data, widowsCountFromDB]);
  const showModal = (i) => {
    const x = [...showdetails];
    x[i] = !x[i];
    setShowDetails(x);
  };
  // console.log(data, showdetails);
  const sponsorPayment = (id) => {
    setSponsorID(id);
    showModalPay();
  };
  const payment_config = {
    public_key: "FLWPUBK-23da88c133fab96b8803a1fda5b98986-X",
    tx_ref: Date.now(),
    amount,
    currency: "NGN",
    payment_options: "card,mobilemoney,ussd",
    customer: {
      email: form.getFieldValue("email"),
      name: form.getFieldValue("name"),
    },
    customizations: {
      title: "JULIET EFEMENA FOUNDATION",
      description: `Sponsorship to ${form.getFieldValue("name")}`,
      logo: "https://github.com/itz-hassan/image/blob/main/logo.png?raw=true",
    },
  };
  const handleFlutterPayment = useFlutterwave(payment_config);

  const onFinish = async (values) => {
    const sponsor = await data.find((el) => el._id === sponsorID);

    handleFlutterPayment({
      callback: (response) => {
        if (response.status === "successful") {
          notification.success({
            message: `Payment received successfuly. Thanks for sponsoring ${
              sponsor && sponsor.name
            }`,
          });
          const transactionDetail = {
            name: response.customer.name,
            email: response.customer.email,
            phone: response.customer.phone_number
              ? response.customer.phone_number
              : "",
            status: response.status,
            transaction_id: response.transaction_id,
            tx_ref: response.tx_ref,
            currency: response.currency,
            amount: response.amount,
            flw_ref: response.flw_ref,
            doneeID: sponsor._id,
            doneeName: sponsor.name,
            role: sponsor.role,
            purpose: "sponsorship",
          };
          result.request(transactionDetail);

          form.resetFields();
          setIsModalOpen(false);
        }
        closePaymentModal(); // this will close the modal programmatically
      },
      onClose: (incomplete) => {
        console.log(incomplete);
        notification.warning({
          message: "Sorry payment failed, try again.",
        });
      },
    });
  };
  const paginationNav = (
    <Pagination
      onChange={(page, pageSize) => setPageNumber(page)}
      size="small"
      total={total}
      pageSize={pageSize}
      hideOnSinglePage={true}
    />
  );

  return (
    <div
      id="orphans"
      className="text-center"
      data-aos="fade"
      data-aos-duration="2000"
    >
      <div className="section-title">
        <h2>Widows Cause</h2>
      </div>
      <div>
        <Modal
          title="Donate Today!!!"
          open={isModalOpen}
          onOk={handleOk}
          style={{ opacity: 0.98 }}
          onCancel={handleCancel}
        >
          {" "}
          <br />
          <p
            style={{
              fontStyle: "italic",
              marginTop: 10,
            }}
          >
            Change a life today by sponsoring a widow.
          </p>
          <div>
            <Form
              name="Info"
              initialValues={{}}
              onFinish={onFinish}
              form={form}
              layout={"vertical"}
            >
              <Item
                label="Full Name of sponsorer"
                name="name"
                rules={[
                  {
                    type: "string",
                    min: 3,
                    max: 255,
                  },
                ]}
              >
                <Input />
              </Item>
              <Item
                label="Email"
                name="email"
                rules={[
                  {
                    type: "email",
                    required: true,
                  },
                ]}
              >
                <Input />
              </Item>
              
                      
    <CurrencyInput
className="ant-myinput"
id="amount"
  name="amount"
  prefix="₦"
  placeholder="Please enter an amount"
 
  decimalsLimit={2}
  onValueChange={(value, name) => setAmount(value)}
/>;
              <Item>
                <Button type="primary" htmlType="submit">
                  Pay Now
                </Button>
              </Item>
            </Form>
          </div>
        </Modal>
        <div className="m_container">
          <div className="cards" data-aos="fade-left" data-aos-duration="3000">
            {data
              ? data.map((d, idx) => {
                  if (d.role === "widow")
                    return (
                      <div className="card" key={d._id}>
                        <div className="person-image / bg-purple">
                          <Link to={`/viewMore/${d._id}`}>
                            <img
                              className="img-c card_img"
                              src={
                                d.avatar.path === "assets/default.svg"
                                  ? d.avatar.path
                                  : `${config.baseURL}api/beneficiaries/downloadProfilePic?fileName=${d.avatar.path}`
                              }
                              alt=""
                            />
                          </Link>
                          {showdetails[idx] ? (
                            <div className="details-info">
                              <span>
                                <strong>Gender: </strong>
                                {d.gender.toUpperCase()}
                              </span>
                              <br />
                              <span>
                                <strong>DOB: </strong>
                                {moment(d.dob).format("MMMM Do YYYY")}
                              </span>
                              <br />
                              <span>
                                <strong>Age: </strong>
                                {moment().diff(d.dob, "years", false)} years old
                              </span>
                              <br />
                              <span>
                                <strong>Email: </strong>
                                {d.email}
                              </span>
                              <br />
                              <span>
                                <strong>Phone: </strong>
                                {d.phone}
                              </span>
                              <br />
                              <span>
                                <strong>Story: </strong>
                                {d.story}
                              </span>
                              <br />
                              <br />
                              <Link
                                to={`/viewMore/${d._id}`}
                                style={{ color: "#fff" }}
                                className="button-c"
                                // state={{ id: "765" }}
                              >
                                Learn more
                              </Link>
                              <br />
                            </div>
                          ) : null}
                          <div className="text-block">
                            <span
                              className="text-item"
                              onClick={() => showModal(idx)}
                            >
                              {" "}
                              {showdetails ? (
                                <MinusOutlined />
                              ) : (
                                <PlusOutlined />
                              )}
                              Details
                            </span>
                          </div>
                          <ShareButton dataID={d._id} />
                        </div>
                        <div className="container-c">
                          <div className="text-name">
                            <span>{d.name}</span>
                          </div>
                          <hr />
                          <div className="text-details">
                            <p>
                              {moment().diff(d.dob, "years", false)} years old{" "}
                              <CaretDownOutlined /> Nigeria{" "}
                            </p>
                          </div>
                          <button
                            className="text-btn"
                            onClick={() => sponsorPayment(d._id)}
                          >{`Sponsor ${
                            d.gender === "male" ? "him" : "her"
                          } now`}</button>
                        </div>
                      </div>
                    );
                })
              : "Loading"}
            {data.length < 1 ? <Empty style={{ textAlign: "center" }} /> : null}
          </div>
        </div>
      </div>
    </div>
  );
};
