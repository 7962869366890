import { Link } from "react-router-dom";
import {
  FileOutlined,
  PieChartOutlined,
  UserOutlined,
  DesktopOutlined,
  TeamOutlined,
  SettingOutlined,
  CreditCardOutlined,
  ProjectOutlined,
  PushpinOutlined,
  FolderOpenOutlined,
} from "@ant-design/icons";

export function getItem(label, key, icon, children) {
  return {
    key,
    icon,
    children,
    label,
  };
}

export const items = [
  getItem(
    "Dashboard",
    "8",
    <Link to="/dashboard">
      <DesktopOutlined />
    </Link>
  ),
  getItem("User", "sub0", <TeamOutlined />, [
    getItem(
      "Add User",
      "0",
      <Link to="/staffRegistration">
        <UserOutlined />
      </Link>
    ),
    getItem("Users List", "1", <Link to="/staffReport" />),
  ]),
  getItem("Beneficiaries", "sub1", <CreditCardOutlined />, [
    getItem("Add Beneficiary", "20", <Link to="/doneeRegistration" />),
    getItem("Beneficiary report", "3", <Link to="/doneeReport" />),
  ]),
  getItem("Events", "sub2", <PushpinOutlined />, [
    getItem("Add Event", "64", <Link to="/addEvent" />),
    getItem("Events/activities", "5", <Link to="/eventReport" />),
  ]),
  getItem("Projects", "sub3", <ProjectOutlined />, [
    getItem("Add project", "687", <Link to="/addProject" />),
    getItem("Project list", "7", <Link to="/projectReport" />),
  ]),

  // getItem("Donees", "2", <PieChartOutlined />),
  // getItem("Files", "9", <FileOutlined />),

  // getItem("Donees", "52", <PieChartOutlined />),
  // getItem("Option 2d", "26", <DesktopOutlined />),
  getItem("Settings", "sub4", <SettingOutlined />, [
    getItem("Founder's Message", "10", <Link to="/founderProfile" />),
    // getItem("Gallery", "9", <FolderOpenOutlined />, [
    getItem("Gallery", "68s7", <Link to="/galleryImage" />),
    // getItem("Video Gallery", "7dw", <Link to="/galleryVideo" />),
    // getItem("Report", "607", <Link to="/galleryReport" />),
    // ]),
    // getItem("", "11"),
    // getItem("Team 2", "8"),
  ]),
];
