import React, { useState, useEffect, useContext } from "react";
import "./styles.login.css";
import { Button, Row, Input, Form } from "antd";
// import logo from "../assets/logo.png";
import logo from "./logo.png";
import AuthContext from "../context/authContext/authContext";
import validateMessages from "../config/validateMessages";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { UserOutlined, LockOutlined } from "@ant-design/icons";

const Login = (props) => {
  const { login, isAuthenticated, error, clearErrors, loading, user } =
    useContext(AuthContext);
  const [isLoading, setIsLoading] = useState(false);

  const navigate = useNavigate();

  const onSubmit = (values) => {
    setIsLoading(true);
    // console.log(values);
    login(values);
    if (error !== null) {
      setIsLoading(false);
      return;
    }
    // console.log(loading, error);
    clearErrors();
    setIsLoading(false);
  };

  useEffect(() => {
    if (isAuthenticated) {
      console.log("login succesfully", user);
      navigate("/dashboard");
      clearErrors();
    }
    // eslint-disable-next-line
  }, [isAuthenticated, props.history]);
  return (
    <div style={{ height: "100vh", paddingTop: 72 }}>
      <div className="form-d">
        <Link to="/">
          <div className="logoImage">
            <img alt="logo" src={logo} />{" "}
          </div>
        </Link>
        <div className="logo">
          <span>Login Page</span>
        </div>
        <Form onFinish={onSubmit} validateMessages={validateMessages}>
          <Form.Item
            name="email"
            rules={[{ required: true }]}
            hasFeedback
            className="my-form"
          >
            <Input
              placeholder={"Email"}
              prefix={<UserOutlined className="site-form-item-icon" />}
            />
          </Form.Item>

          <Form.Item
            name="password"
            rules={[{ required: true }]}
            hasFeedback
            className="my-form"
          >
            <Input
              type="password"
              placeholder={"Password"}
              prefix={<LockOutlined className="site-form-item-icon" />}
            />
          </Form.Item>
          <Row>
            <Button
              className="my-form"
              type="primary"
              htmlType="submit"
              loading={isLoading}
              disabled={isLoading}
            >
              LOGIN
            </Button>
          </Row>

          {/* <div className="my-form">
            <h1>
              <Link to="/forgot-password" className="link">
                Forgot password?
              </Link>
            </h1>
          </div> */}
          <div className="my-form">
            <h1>
              Don't have an account?
              <a href="/adminRegister" className="correct-text link">
                &nbsp; Register now.
              </a>
            </h1>
          </div>
        </Form>
      </div>
    </div>
  );
};
export default Login;
