import React, { useEffect, useState } from "react";
import AppDatePicker from "../../components/AppDatePicker";
import MyDatePicker from "../../components/MyDatePicker";
import { Select, Option } from "../../components/AppSelect";
import AppSubmitButton from "../../components/AppButton";
import { Form, DatePicker, notification } from "antd";
import useApiGet from "../../hooks/useApiGet";
import AppInput from "../../components/AppInput";
import moment from "moment";
import { useNavigate, useLocation } from "react-router-dom";
import API from "../../config/API";

const { Item: Field } = Form;
const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 12 },
};
const tailLayout = {
  wrapperCol: { offset: 8, span: 12 },
};

const EditStaffRegistered = () => {
  const location = useLocation();
  let navigate = useNavigate();
  const [form] = Form.useForm();

  // const result = useApiUpdate("/api/users/update/" + location.state.id);
  const { data, request } = useApiGet("/api/users/" + location.state.id);
  const [disable, setDisable] = useState(false);

  useEffect(() => {
    request();
  }, [location]);

  useEffect(() => {
    form.setFieldsValue({
      first_name: data.first_name,
      last_name: data.last_name,
      gender: data.gender,
      dob: moment(data.dob),
      phone: data.phone,
      email: data.email,
      role: data.role,
    });
  }, [data]);

  const handleSubmit = (values) => {
    const data = { ...values };
    API.put(`/api/users/update/${location.state.id}`, data)
      .then((res) => {
        // console.log(res.data);
        navigate("/staffReport");
      })
      .catch((err) =>
        notification.error({
          message: "Connection Error",
          description: err ? err : "Oops something went wrong !!!",
          duration: 10,
        })
      );
  };

  return (
    <div>
      <h4>Edit User</h4>
      <Form
        {...layout}
        scrollToFirstError
        name="User-component"
        form={form}
        // validateMessages={validateMessages}
        onFinish={handleSubmit}
      >
        <Field
          name="first_name"
          label="First Name"
          tooltip="This is a required field"
          rules={[{ required: true, type: "text" }]}
        >
          <AppInput style={{ width: 250 }} />
        </Field>
        <Field
          name="last_name"
          label="Last Name"
          tooltip="This is a required field"
          rules={[{ required: true, type: "text" }]}
        >
          <AppInput style={{ width: 250 }} />
        </Field>
        <Field
          name="gender"
          label="Select Gender"
          tooltip="This is a required field"
          rules={[
            {
              required: true,
              type: "string",
            },
          ]}
        >
          <Select style={{ width: 250 }} placeholder="Select">
            <Option key={1} value="male">
              Male
            </Option>
            <Option key={2} value="female">
              Female
            </Option>
          </Select>
        </Field>

        <Field
          name="role"
          label="Role"
          tooltip="This is a required field"
          rules={[
            {
              required: true,
              type: "string",
            },
          ]}
        >
          <Select style={{ width: 250 }} placeholder="Select">
            <Option key={1} value="admin">
              Admin
            </Option>
            <Option key={2} value="volunteer">
              Volunteer
            </Option>
            <Option key={3} value="staff">
              Staff
            </Option>
          </Select>
        </Field>

        <Field
          shouldUpdate
          name="dob"
          label="Date of Birth"
          tooltip="This is a required field"
          rules={[
            {
              required: true,
              type: "date",
            },
          ]}
        >
          <DatePicker
            picker="date"
            // onChange={(e) => e}
            dateFormat="dd/MM/yyyy"
            placeholderText="Enter Date"
            style={{ width: 250 }}
          />
        </Field>

        <Field
          name="email"
          label="Email"
          tooltip="This is a required field"
          rules={[{ required: true, type: "email" }]}
        >
          <AppInput style={{ width: 250 }} />
        </Field>

        <Field
          name="phone"
          label="Phone"
          tooltip="This is a required field"
          rules={[{ required: true, type: "text" }]}
        >
          <AppInput style={{ width: 250 }} />
        </Field>

        {/* <Field
          name="password"
          label="Password"
          tooltip="This is a required field"
          rules={[{ required: true, type: "text" }]}
        >
          <Password placeholder="input password" style={{ width: 250 }} />
        </Field> */}
        <Field {...tailLayout}>
          <AppSubmitButton disabled={disable} htmlType="submit" />
        </Field>
      </Form>
    </div>
  );
};

export default EditStaffRegistered;
