import React, { useState, useEffect, useContext } from "react";
// import "./register.css";
import { Button, Row, Input, Form, Select, DatePicker, notification } from "antd";
import logo from "./logo.png";
import AuthContext from "../context/authContext/authContext";
import validateMessages from "../config/validateMessages";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import {
  UserOutlined,
  LockOutlined,
  PhoneOutlined,
  MailOutlined,
} from "@ant-design/icons";

const { Option } = Select;

const AdminRegister = (props) => {
  const [isVerified, setIsVerified] = useState(false); // Tracks if the user entered the correct PIN
  const [pin, setPin] = useState(""); // Stores the entered PIN
  const [isLoading, setIsLoading] = useState(false);

  const { register, isAuthenticated, error, clearErrors, loading, user } =
    useContext(AuthContext);

  const navigate = useNavigate();

  const verifyPin = () => {
    const correctPin = "8756"; // Replace with your actual secure PIN
    if (pin === correctPin) {
      setIsVerified(true);
      notification.success({
        message: "Access Granted",
        description: "You can now register an account.",
        placement: "topRight",
      });
    } else {
      notification.error({
        message: "Access Denied",
        description: "Incorrect PIN. Please try again.",
        placement: "topRight",
      });
    }
  };

  const onSubmit = (values) => {
    setIsLoading(true);
    register(values);
    if (error !== null) {
      setIsLoading(false);
      return;
    }
    clearErrors();
    setIsLoading(false);
  };

  useEffect(() => {
    if (isAuthenticated) {
      notification.success({
        message: "Registration Successful",
        description: "Your account has been registered successfully!",
        placement: "topRight",
      });

      navigate("/dashboard");
      clearErrors();
    }
    // eslint-disable-next-line
  }, [isAuthenticated, props.history]);

  if (!isVerified) {
    return (
      <div style={{ paddingTop: 72 }}>
        <div className="form-d">
          <Link to="/">
            <div className="logoImage">
              <img alt="logo" src={logo} />
            </div>
          </Link>
          <div className="logo">
            <span>Enter PIN</span>
          </div>
          <Form onFinish={verifyPin}>
            <Form.Item
              name="pin"
              rules={[{ required: true, message: "PIN is required" }]}
              className="my-form"
            >
              <Input.Password
                placeholder="Enter PIN"
                value={pin}
                onChange={(e) => setPin(e.target.value)}
              />
            </Form.Item>
            <Row>
              <Button
                className="my-form"
                type="primary"
                htmlType="submit"
                disabled={isLoading}
              >
                VERIFY
              </Button>
            </Row>
          </Form>
        </div>
      </div>
    );
  }

  return (
    <div style={{ paddingTop: 72 }}>
      <div className="form-d">
        <Link to="/">
          <div className="logoImage">
            <img alt="logo" src={logo} />
          </div>
        </Link>
        <div className="logo">
          <span>Register Page</span>
        </div>
        <Form onFinish={onSubmit} validateMessages={validateMessages}>
          <Form.Item
            name="first_name"
            rules={[{ required: true, message: "First name is required" }]}
            className="my-form"
          >
            <Input
              placeholder="First Name"
              prefix={<UserOutlined className="site-form-item-icon" />}
            />
          </Form.Item>
          <Form.Item
            name="last_name"
            rules={[{ required: true, message: "Last name is required" }]}
            className="my-form"
          >
            <Input
              placeholder="Last Name"
              prefix={<UserOutlined className="site-form-item-icon" />}
            />
          </Form.Item>
          <Form.Item
            name="email"
            rules={[{ required: true, type: "email" }]}
            className="my-form"
          >
            <Input
              placeholder="Email"
              prefix={<MailOutlined className="site-form-item-icon" />}
            />
          </Form.Item>
          <Form.Item
            name="password"
            rules={[{ required: true }]}
            className="my-form"
          >
            <Input.Password
              placeholder="Password"
              prefix={<LockOutlined className="site-form-item-icon" />}
            />
          </Form.Item>
          <Form.Item
            name="phone"
            rules={[{ required: true, message: "Phone number is required" }]}
            className="my-form"
          >
            <Input
              placeholder="Phone"
              prefix={<PhoneOutlined className="site-form-item-icon" />}
            />
          </Form.Item>
          <Form.Item
            name="gender"
            rules={[{ required: true, message: "Gender is required" }]}
            className="my-form"
          >
            <Select placeholder="Gender">
              <Option value="Male">Male</Option>
              <Option value="Female">Female</Option>
            </Select>
          </Form.Item>
          <Form.Item
            name="dob"
            rules={[{ required: true, message: "Date of birth is required" }]}
            className="my-form"
          >
            <DatePicker style={{ width: "100%" }} placeholder="Date of Birth" />
          </Form.Item>
          <Form.Item
            name="role"
            rules={[{ required: true, message: "Role is required" }]}
            className="my-form"
          >
            <Select placeholder="Role">
              <Option value="admin">Admin</Option>
              <Option value="staff">Staff</Option>
            </Select>
          </Form.Item>
          <Row>
            <Button
              className="my-form"
              type="primary"
              htmlType="submit"
              loading={isLoading}
              disabled={isLoading}
            >
              REGISTER
            </Button>
          </Row>
        </Form>
      </div>
    </div>
  );
};

export default AdminRegister;
