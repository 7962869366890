import React from "react";
import { Button } from "antd";

export default function AppSubmitButton({
  title = "Submit",
  size = "medium",
  loading,
  disabled,
  type,
  ...otherProps
}) {
  return (
    <div>
      <Button
        loading={loading}
        size={size}
        {...otherProps}
        disabled={disabled}
        shape="round"
        type={type}
      >
        {title}
      </Button>
    </div>
  );
}
