import React, { useEffect, useState, useLayoutEffect } from "react";
import AppSubmitButton from "../../components/AppButton";
import AppTextArea from "../../components/AppTextArea";
import useApiPut from "../../hooks/useApiUp";
import { UploadOutlined } from "@ant-design/icons";
import { Form, message, Upload, Button, notification } from "antd";
import useApiGet from "../../hooks/useApiGet";
import AppInput from "../../components/AppInput";
import API from "../../config/API";

const { Item: Field } = Form;
const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 12 },
};
const tailLayout = {
  wrapperCol: { offset: 8, span: 12 },
};

const GalleryImage = () => {
  const [form] = Form.useForm();

  // const result = useApiPut("/api/app_configs/founder");
  const { data, request, loading } = useApiGet("/api/app_configs");
  const [disable, setDisable] = useState(false);

  useEffect(() => {
    request();
    return;
  }, []);

  useLayoutEffect(() => {
    if (data && data.Founder)
      form.setFieldsValue({
        first_name: data.Founder.first_name,
        last_name: data.Founder.last_name,
        comment: data.Founder.message,
      });

    return;
  }, [data]);

  const dummyRequest = ({ file, onSuccess }) => {
    setTimeout(() => {
      onSuccess("ok");
    }, 0);
  };

  const props = {
    type: "drag",
    // beforeUpload: (file) => {
    //   const isJPEG = file.type === "image/jpeg";
    //   if (!isJPEG) {
    //     message.error(`${file.name} is not a jpeg file`);
    //   }
    //   return isJPEG || Upload.LIST_IGNORE;
    // },
    // maxCount: 1,
    multiple: true,
    // onChange: (e) => setFiles(e.file.originFileObj),
    customRequest: dummyRequest,
  };

  const handleSubmit = (values) => {
    const formData = new FormData();
    // const payload = {
    //   Founder: {
    //     first_name: values.first_name,
    //     last_name: values.last_name,
    //     message: values.comment,
    //   },
    // };

    for (var i = 0; i < values.images.fileList.length; i++) {
      formData.append("media", values.images.fileList[i].originFileObj);
    }

    API.put("/api/app_configs/gallery", formData)
      .then((res) => {
        message.success({
          content: "Successful!!!",
          duration: 5,
        });
        // navigate("/doneeReport");
      })
      .catch((err) =>
        notification.error({
          message: "Connection Error",
          description: err ? err : "Oops something went wrong !!!",
          duration: 10,
        })
      );

    // console.log(payload);
  };

  return (
    <div>
      <h4>Gallery </h4>
      <Form
        {...layout}
        scrollToFirstError
        name="founderProfile-component"
        form={form}
        onFinish={handleSubmit}
      >
        <Field
          name="images"
          label="Upload"
          tooltip="Only Jpeg and mp4 files are acceptable"
          rules={[{ required: true }]}
        >
          <Upload
            getValueFromEvent={({ file }) => file.originFileObj}
            {...props}
          >
            <Button disabled={disable} icon={<UploadOutlined />}>
              Click to Upload
            </Button>
          </Upload>
        </Field>

        <Field {...tailLayout}>
          <AppSubmitButton
            // disabled={result.loading}
            // loading={result.loading}
            htmlType="submit"
          />
        </Field>
      </Form>
    </div>
  );
};

export default GalleryImage;
