import React, { useContext } from "react";
import { Route, Navigate } from "react-router-dom";
import AuthContext from "../context/authContext/authContext";

const UnPrivateRoute = ({ children }) => {
  const { isAuthenticated, loading } = useContext(AuthContext);

  return isAuthenticated && loading ? <Navigate to="/dashboard" /> : children;
};

export default UnPrivateRoute;
