import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Table, Input, Switch, message } from "antd";
import { DeleteOutlined } from "@ant-design/icons";
import moment from "moment";
import useApiGet from "../../hooks/useApiGet";
import API from "../../config/API";

const { Search } = Input;

const DoneeReport = () => {
  // const [fetchData, setFetchData] = useState(false);
  const [q, setQ] = useState("");
  const { data, request, loading } = useApiGet("/api/beneficiaries/");

  useEffect(() => {
    request();
  }, []);

  const columns = [
    {
      title: "Role",
      dataIndex: "role",
      render: (el) => el.toUpperCase(),
      onFilter: (value, record) => record.role.toString().indexOf(value) === 0,

      filters: [
        {
          text: "Orphan",
          value: "orphan",
        },
        {
          text: "Widow",
          value: "widow",
        },
        {
          text: "Less-privileged",
          value: "less-privileged",
        },
      ],
      key: "_id",
      width: "90px",
      fixed: "left",
    },
    {
      title: "First Name",
      dataIndex: "first_name",
      render: (el) => el.toUpperCase(),
      sorter: (a, b) => a.first_name.localeCompare(b.first_name),
      key: "_id",
      width: "120px",
      fixed: "left",
    },
    {
      title: "Last Name",
      dataIndex: "last_name",
      render: (el) => el.toUpperCase(),
      sorter: (a, b) => a.last_name.localeCompare(b.last_name),
      key: "_id",
      width: "120px",
      fixed: "left",
    },
    {
      title: "Gender",
      dataIndex: "gender",
      width: "80px",
      onFilter: (value, record) =>
        record.gender.toString().indexOf(value) === 0,

      filters: [
        {
          text: "Male",
          value: "male",
        },
        {
          text: "Female",
          value: "female",
        },
      ],
      key: "_id",
    },
    {
      title: "Date of Birth",
      dataIndex: "dateOfBirth",
      render: (el) => moment(el).format("DD-MMM-yyyy"),
      width: "120px",
      key: "_id",
    },
    {
      title: "Phone",
      dataIndex: "phone",
      width: "120px",
      key: "_id",
    },

    {
      title: "Nationality",
      dataIndex: "nationality",
      render: (a, el) => (el.nationality ? el.nationality.nationality : ""),
      width: "120px",
      key: "_id",
    },
    {
      title: "State of Origin",
      dataIndex: "stateOfOrigin",
      render: (a, el) => (el.nationality ? el.nationality.stateOfOrigin : ""),
      width: "120px",
      key: "_id",
    },
    {
      title: "LGA",
      dataIndex: "localGovernmentArea",
      render: (a, el) =>
        el.nationality ? el.nationality.localGovernmentArea : "",
      width: "90px",
      key: "_id",
    },
    {
      title: "Account Detail",
      children: [
        {
          title: "Account Number",
          dataIndex: "bank_details",
          render: (a, el) =>
            el.bank_details && el.bank_details.acct_no !== "undefined"
              ? el.bank_details.acct_no
              : "",
          key: "_id",
          width: "150px",
        },
        {
          title: "Account Name",
          dataIndex: "bank_details",
          render: (a, el) =>
            el.bank_details && el.bank_details.acct_name !== "undefined"
              ? el.bank_details.acct_name
              : "",
          width: "150px",
          key: "_id",
        },
        {
          title: "Bank",
          dataIndex: "bank",
          render: (a, el) =>
            el.bank_details && el.bank_details.bank !== "undefined"
              ? el.bank_details.bank
              : "",
          width: "90px",
          key: "_id",
        },
      ],
    },
    {
      title: "Level of Education",
      dataIndex: "levelOfEduc",
      width: "150px",
      key: "_id",
    },
    {
      title: "Skills",
      dataIndex: "skills",
      render: (el) => (
        <ul>
          {el.map((n) => {
            return <li>{n}</li>;
          })}{" "}
        </ul>
      ),
      width: "90px",
      key: "_id",
    },
    {
      title: "Employment History",
      dataIndex: "employment_history",
      render: (el) =>
        el
          ? el.map((n) => {
              return (
                <ul>
                  <li>{n}</li>
                </ul>
              );
            })
          : "",
      key: "_id",
      width: "140px",
    },
    {
      title: "ID No",
      dataIndex: "validID",
      render: (el) => (el ? el.id_no : ""),
      width: "90px",
      key: "_id",
    },
    {
      title: "ID Type",
      dataIndex: "validID",
      render: (el) => (el ? el.id_type : ""),
      width: "90px",
      key: "_id",
    },
    {
      title: "Story",
      dataIndex: "story",
      width: "170px",

      key: "_id",
    },
    {
      title: "Residential Address",
      children: [
        {
          title: "House No",
          dataIndex: "nationality",
          render: (a, el) =>
            el.nationality &&
            el.nationality.residentialAddress.houseNo !== "undefined"
              ? el.nationality.residentialAddress.houseNo
              : "",
          width: "90px",
          key: "_id",
        },
        {
          title: "Street",
          dataIndex: "nationality",
          render: (a, el) =>
            el.nationality &&
            el.nationality.residentialAddress.street !== "undefined"
              ? el.nationality.residentialAddress.street
              : "",
          width: "90px",
          key: "_id",
        },
        {
          title: "State",
          dataIndex: "nationality",
          render: (a, el) =>
            el.nationality &&
            el.nationality.residentialAddress.state !== "undefined"
              ? el.nationality.residentialAddress.state
              : "",
          width: "90px",
          key: "_id",
        },
        {
          title: "Country",
          dataIndex: "nationality",
          render: (a, el) =>
            el.nationality &&
            el.nationality.residentialAddress.country !== "undefined"
              ? el.nationality.residentialAddress.country
              : "",
          width: "90px",
          key: "_id",
        },
      ],
    },
    {
      title: "Action",
      dataIndex: "_id",
      width: "90px",
      key: "_id",
      align: "center",

      render: (id, el) => {
        let checkedval = el.isVerified;
        return (
          <span>
            <Switch
              checked={el.isVerified}
              onChange={(e) => {
                API.put(`/api/beneficiaries/approval/${el._id}`, {
                  isVerified: e,
                })
                  .then((res) => {
                    request();
                    // console.log(res.data);
                    // // checkedval = res.data.isVerified;
                    // el = res.data;
                    message.success({
                      content: "Successful",
                      duration: 2,
                    });
                  })
                  .catch((err) =>
                    message.error({
                      content: "Oops! something went wrong. Try again",
                    })
                  );
              }}
            />
            <Link to="/editDonee" state={{ id: id }}>
              Edit
            </Link>
            <DeleteOutlined
              onClick={() => {
                API.delete(`/api/beneficiaries/${id}`)
                  .then(() => {
                    request();
                    message.success({
                      content: "Successful",
                      duration: 2,
                    });
                  })
                  .catch((err) =>
                    message.error({
                      content: "Oops! something went wrong. Try again",
                    })
                  );
              }}
            />
          </span>
        );
      },
    },
  ];

  const search = (data) => {
    return data.filter((record) => {
      return (
        record.gender.toString().toLowerCase().indexOf(q.toLowerCase()) > -1 ||
        record.role.toString().toLowerCase().indexOf(q.toLowerCase()) > -1 ||
        record.first_name.toString().toLowerCase().indexOf(q.toLowerCase()) >
          -1 ||
        record.last_name.toString().toLowerCase().indexOf(q.toLowerCase()) > -1
      );
    });
  };

  return (
    <div>
      <div>
        <h6 align="center" style={{ marginLeft: "20px" }}>
          <h3>
            <em>JULIET EFEMENA FOUNDATION</em>
          </h3>
          <br />
        </h6>
        <h4 align="center"> Beneficiary Report </h4>
        <br />{" "}
        <Search
          placeholder="Search"
          onChange={(e) => setQ(e.target.value)}
          enterButton
          style={{ width: 200 }}
        />
        <br />
        <Table
          scroll={{ y: 2300, x: 2300 }}
          columns={columns}
          bordered
          loading={loading}
          pagination
          dataSource={search(data)}
          rowKey={(record) => record._id}
          size="small"
        />
      </div>
    </div>
  );
};
export default DoneeReport;
