import React, { useEffect, useState, useLayoutEffect } from "react";
import AppSubmitButton from "../../components/AppButton";
import AppTextArea from "../../components/AppTextArea";
import useApiPut from "../../hooks/useApiUp";
import { UploadOutlined } from "@ant-design/icons";
import { Form, message, Upload, Button, notification } from "antd";
import useApiGet from "../../hooks/useApiGet";
import AppInput from "../../components/AppInput";
import API from "../../config/API";

const { Item: Field } = Form;
const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 12 },
};
const tailLayout = {
  wrapperCol: { offset: 8, span: 12 },
};

const FounderProfile = () => {
  const [form] = Form.useForm();
  const [isVisible, setIsVisible] = useState(false);

  const result = useApiPut("/api/app_configs/founder");
  const { data, request, loading } = useApiGet("/api/app_configs");
  const [disable, setDisable] = useState(false);

  useEffect(() => {
    request();
    return;
  }, []);

  useLayoutEffect(() => {
    if (data && data.Founder)
      form.setFieldsValue({
        first_name: data.Founder.first_name,
        last_name: data.Founder.last_name,
        comment: data.Founder.message,
      });

    return;
  }, [data]);

  const props = {
    type: "drag",
    beforeUpload: (file) => {
      const isJPEG = file.type === "image/jpeg";
      if (!isJPEG) {
        message.error(`${file.name} is not a jpeg file`);
      }
      return isJPEG || Upload.LIST_IGNORE;
    },
    maxCount: 1,
    multiple: true,
    // onChange: (e) => setFiles(e.file.originFileObj),
    customRequest: ({ file, onSuccess }) => {
      setTimeout(() => {
        onSuccess("ok");
      }, 0);
    },
  };

  const handleSubmit = (values) => {
    const payload = {
      Founder: {
        first_name: values.first_name,
        last_name: values.last_name,
        message: values.comment,
      },
    };

    API.put("/api/app_configs/founder", payload)
      .then((res) => {
        message.success({
          content: "Successful!!!",
          duration: 5,
        });
        // navigate("/doneeReport");
      })
      .catch((err) =>
        notification.error({
          message: "Connection Error",
          description: err ? err : "Oops something went wrong !!!",
          duration: 10,
        })
      );

    console.log(payload);
  };

  return (
    <div>
      <h4>Founder Profile </h4>
      <Form
        {...layout}
        scrollToFirstError
        name="founderProfile-component"
        form={form}
        onFinish={handleSubmit}
      >
        <Field
          name="profilePicture"
          label="Profile Pricture"
          tooltip="Only Jpeg files are acceptable"
          rules={[{ required: false }]}
        >
          <Upload
            getValueFromEvent={({ file }) => file.originFileObj}
            {...props}
          >
            <Button disabled={disable} icon={<UploadOutlined />}>
              Click to Upload
            </Button>
          </Upload>
        </Field>
        <Field
          name="first_name"
          label="First Name"
          tooltip="This is a required field"
          rules={[{ required: true, type: "text" }]}
        >
          <AppInput style={{ width: 250 }} />
        </Field>
        <Field
          name="last_name"
          label="Enter Last Name"
          tooltip="This is a required field"
          rules={[{ required: true, type: "text" }]}
        >
          <AppInput style={{ width: 250 }} />
        </Field>

        <Field
          name="comment"
          label="Enter your message"
          tooltip="This is a required field"
          rules={[{ required: true, type: "text" }]}
        >
          <AppTextArea style={{ width: 550, height: 250 }} />
        </Field>
        <Field {...tailLayout}>
          <AppSubmitButton disabled={disable} htmlType="submit" />
        </Field>
      </Form>
    </div>
  );
};

export default FounderProfile;
