import React from "react";
import { Input } from "antd";
//import TextArea from "antd/lib/input/TextArea";
const { TextArea } = Input;
export default function AppTextArea({
  width = "100%",
  disabled,
  ...otherProps
}) {
  return (
    <div>
      <TextArea
        width={width}
        className="form-control"
        {...otherProps}
        disabled={disabled}
      />
    </div>
  );
}
