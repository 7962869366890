import React, { useEffect, useState, useContext } from "react";
import { Tabs, Carousel, Empty, Image } from "antd";
import useApiGet from "../hooks/useApiGet";
import config from "../config/default.json";
import "../css/project.css";

const contentStyle = {
  height: "160px",
  color: "#fff",
  lineHeight: "160px",
  textAlign: "center",
  background: "#364d79",
};

export const Project = (props) => {
  const [currentData, setCurrentData] = useState({});
  const [postData, setPostData] = useState({});
  const [futureData, setFutureData] = useState({});

  const { data, request, loading } = useApiGet("/api/projects/all");
  // const checkPeriod = () => {
  //   if(data.length > 1) {
  //       data.re
  //   }
  // }
  useEffect(() => {
    request();
  }, []);
  useEffect(() => {
    if (data.length >= 1) {
      setCurrentData(data.find((el) => el.category === "current"));
      setFutureData(data.find((el) => el.category === "future"));
      setPostData(data.find((el) => el.category === "post"));
    }
  });

  const CurrentProject = () => {
    return (
      <div id="currentProject">
        <h3>{currentData && currentData.title}</h3>
        <p>{currentData && currentData.description}</p>
        <div className="project_row">
          <div className="project_item">
            <h4>Current Project Status</h4>
            <Carousel autoplay>
              {currentData && currentData.upload ? (
                currentData.upload.map((el, idx) => (
                  <div>
                    <Image
                      className="project_img"
                      alt=" "
                      src={
                        currentData.upload
                          ? `${config.baseURL}api/projects/downloadEventMedia?fileName=${el.path}`
                          : ""
                      }
                    />
                  </div>
                ))
              ) : (
                <Empty />
              )}
            </Carousel>
          </div>
          <div className="project_item">
            <h4>Proposed Project</h4>
            <Carousel autoplay key={2} easing="easeOut" effect="scrollx">
              {currentData && currentData.uploadPrp ? (
                currentData.uploadPrp.map((el, idx) => (
                  <div>
                    <Image
                      className="project_img"
                      alt=" "
                      src={
                        currentData.uploadPrp
                          ? `${config.baseURL}api/projects/downloadEventMedia?fileName=${el.path}`
                          : ""
                      }
                    />
                  </div>
                ))
              ) : (
                <Empty />
              )}
            </Carousel>
          </div>
        </div>
      </div>
    );
  };
  const PostProject = () => {
    return (
      <div id="postProject">
        <h3>Post project</h3>
        <h3>{postData && postData.title}</h3>
        <p>{postData && postData.description}</p>
        <Carousel autoplay key={3} easing="easeOut" effect="scrollx">
          {postData && postData.upload ? (
            postData.upload.map((el, idx) => (
              <div>
                <Image
                  className="project_img"
                  alt=" "
                  src={
                    postData.upload
                      ? `${config.baseURL}api/projects/downloadEventMedia?fileName=${el.path}`
                      : ""
                  }
                />
              </div>
            ))
          ) : (
            <Empty />
          )}
        </Carousel>
      </div>
    );
  };
  const FutureProject = ({ id }) => {
    return (
      <div id={id}>
        <h3>Future project</h3>
        <h3>{futureData && futureData.title}</h3>
        <p>{futureData && futureData.description}</p>
        <Carousel autoplay key={4} easing="easeOut" effect="scrollx">
          {futureData && futureData.upload ? (
            futureData.upload.map((el, idx) => (
              <div>
                <Image
                  className="project_img"
                  alt=" "
                  src={
                    futureData.upload
                      ? `${config.baseURL}api/projects/downloadEventMedia?fileName=${el.path}`
                      : ""
                  }
                />
              </div>
            ))
          ) : (
            <Empty />
          )}
        </Carousel>
      </div>
    );
  };
  return (
    <div
      id="services"
      className="text-center"
      // data-aos="slide-up"
      // data-aos-duration="2000"
    >
      <div className="container">
        <div className="section-title">
          <h2>Projects</h2>
        </div>
        <Tabs
          // defaultActiveKey="1"
          size="large"
          centered
          tabBarStyle={{
            fontSize: "2rem",
            fontWeight: "bold",
            color: "#fff",
          }}
          // onChange={onChange}
          items={[
            {
              label: `Current`,
              key: "1",
              children: <CurrentProject />,
            },
            {
              label: `Post`,
              key: "2",
              children: <PostProject />,
            },
            {
              label: `Future`,
              key: "3",
              children: <FutureProject id="futureProject" />,
            },
          ]}
        />
      </div>
    </div>
  );
};
