import React, { useContext, useState } from "react";
import { Form, Input, Button, message, notification } from "antd";
import API from "../config/API";
import AuthContext from "../context/authContext/authContext";

const ChangePassword = () => {
  const [loadingV, setLoadingV] = useState(false);
  const [loading, setLoading] = useState(false);
  const [showChangePassword, setShowChangePassword] = useState(false);
  const { user } = useContext(AuthContext);
  const [form] = Form.useForm();
  const [formC] = Form.useForm();
  const verifyPassword = (values) => {
    setLoadingV(true);
    API.post("api/users/verifyPassword/" + user._id, {
      password: values.password,
    })
      .then((res) => {
        if (!res.data) message.error("Incorrect password. Please try again");
        setShowChangePassword(res.data);
        setLoadingV(false);
      })
      .catch((err) => {
        console.log(err);
        message.error("Error something went wrong. Please try again");
        setShowChangePassword(false);
        setLoadingV(false);
      });
  };

  const handleSubmitPasswordChange = (values) => {
    setLoading(true);
    const { password, confirmPassword } = values;
    if (password !== confirmPassword) {
      notification.warning({
        message: "Warning",
        description: "Password mismatch check password and try again",
        duration: 5,
      });
      setLoading(false);
      return;
    }
    API.post("api/users/changePassword/" + user._id, { password })
      .then((res) => {
        setShowChangePassword(false);
        setLoading(false);
        notification.success({ message: res.data });
        formC.resetFields();
        form.resetFields();
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };
  return (
    <div className="profile-container">
      <h3>Change Password</h3>
      <Form
        form={form}
        onFinish={verifyPassword}
        layout="inline"
        className="changePassword"
      >
        <Form.Item
          label="Current Password"
          name="password"
          style={{ maxWidth: 500 }}
          rules={[{ required: true, max: 255, min: 4 }]}
        >
          <Input
            placeholder="Enter current password"
            type="password"
            disabled={showChangePassword}
          />
        </Form.Item>
        <Form.Item>
          <Button
            shape="round"
            htmlType="submit"
            loading={loadingV}
            disabled={loadingV || showChangePassword}
          >
            submit
          </Button>
        </Form.Item>
      </Form>
      <br />

      {showChangePassword ? (
        <div>
          <Form
            layout="inline"
            form={formC}
            onFinish={handleSubmitPasswordChange}
          >
            <Form.Item
              label="New Password"
              rules={[{ required: true, min: 3, max: 255, type: "string" }]}
              name="password"
              className="mb-3"
            >
              <Input placeholder="New Password" type="password" />
            </Form.Item>
            <Form.Item
              label="Confirm New Password"
              rules={[{ required: true, min: 3, max: 255, type: "string" }]}
              name="confirmPassword"
              className="mb-3"
            >
              <Input placeholder="Confirm New Password" type="password" />
            </Form.Item>
            <Form.Item>
              <Button
                shape="round"
                htmlType="Save"
                loading={loading}
                disabled={loading}
              >
                submit
              </Button>
            </Form.Item>
          </Form>
        </div>
      ) : null}
    </div>
  );
};

export default ChangePassword;
