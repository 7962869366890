import React, { useEffect, useContext } from "react";

import AuthContext from "../context/authContext/authContext";
import { Result, Button } from "antd";
import { Link } from "react-router-dom";

function ErrorPage() {
  const { loadUser, user } = useContext(AuthContext);

  useEffect(() => {
    loadUser();
    // eslint-disable-next-line
  }, []);

  return (
    <div>
      <div className="loader"></div>
      <Result
        status="404"
        title="404"
        subTitle="Sorry, the page you visited does not exist."
        extra={<Link to="/dashboard">Back Home</Link>}
      />
    </div>
  );
}

export default ErrorPage;
