import React, { useEffect, useState } from "react";
import AppSubmitButton from "../../components/AppButton";
import AppTextArea from "../../components/AppTextArea";
import config from "../../config/default.json";
import { Form, Button, Upload, DatePicker, Image, message } from "antd";
import { DeleteOutlined, UploadOutlined } from "@ant-design/icons";
import useApiGet from "../../hooks/useApiGet";
import AppInput from "../../components/AppInput";
import { useNavigate, useLocation } from "react-router-dom";
import moment from "moment";
import useApiUpdate from "../../hooks/useAPIUpdate";
import AppDatePicker from "../../components/AppDatePicker";
import "./style.css";

import { UploadProps } from "antd";
//import { Button, Upload } from "antd";

const { Item: Field } = Form;
const { RangePicker } = DatePicker;

const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 12 },
};
const tailLayout = {
  wrapperCol: { offset: 8, span: 12 },
};

const EditEvent = (element) => {
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const [files, setFiles] = useState([]);
  const location = useLocation();
  const [fileURL, setFileURL] = useState([]);

  const [disable, setDisable] = useState(false);
  const result = useApiUpdate("/api/events/details/" + location.state.id);
  const { data, request } = useApiGet(
    "/api/events/eventsByID/" + location.state.id
  );

  useEffect(() => {
    request();
  }, [location]);

  const props = {
    type: "drag",
    beforeUpload: (file) => {
      const isJPEG = file.type === "image/jpeg";
      if (!isJPEG) {
        message.error(`${file.name} is not a jpeg file`);
      }
      return isJPEG || Upload.LIST_IGNORE;
    },
    // maxCount: 1,
    multiple: true,
    //onChange: (e) => setFiles(e.file.originFileObj),
    customRequest: ({ file, onSuccess }) => {
      setTimeout(() => {
        onSuccess("ok");
      }, 0);
    },
  };

  form.setFieldsValue({
    description: data.description,
    title: data.title,
    date: [moment(data.startDate), moment(data.endDate)],
    mediaLink: data.Upload,
  });

  const handleSubmit = (values) => {
    const formData = new FormData();

    for (var i = 0; i < values.mediaLink.fileList.length; i++) {
      formData.append("mediaLink", values.mediaLink.fileList[i].originFileObj);
    }

    const postedBy = "63877e3a088b6e368d4f6301";
    formData.append("startDate", values.startDate);
    formData.append("endDate", values.endDate);
    formData.append("title", values.title);
    formData.append("description", values.description);
    formData.append("postedBy", postedBy);
    console.log(values);
    result.request(formData);
    form.resetFields();
  };

  return (
    <div>
      <h4>Edit Event/Project</h4>
      <Form
        {...layout}
        scrollToFirstError
        name="Event-component"
        form={form}
        // validateMessages={validateMessages}
        onFinish={handleSubmit}
      >
        <Field
          name="title"
          label="Title of Event/Project"
          tooltip="This is a required field"
          rules={[{ required: true, type: "text" }]}
        >
          <AppInput style={{ width: 250 }} />
        </Field>
        <Field
          name="description"
          label="Description"
          tooltip="Description of the project/event"
          rules={[{ type: "text" }]}
        >
          <AppTextArea style={{ width: 350 }} />
        </Field>
        <Field name="date" label="Date">
          <RangePicker
            showTime={{
              format: "HH:mm",
            }}
            format="YYYY-MM-DD HH:mm"
          />
        </Field>

        <div id="event-media">
          {data && data.upload && data.upload.length > 0
            ? data.upload.map((media) => (
                <div>
                  <Image
                    // id="img"
                    width={200}
                    height={200}
                    // style={{ width: 100, height: 200 }}
                    src={`${config.baseURL}api/events/downloadEventMedia?fileName=${media.path}`}
                  />
                  <DeleteOutlined />
                </div>
              ))
            : null}
        </div>

        <Field
          name="mediaLink"
          label="Upload"
          tooltip="This is a required field"
          rules={[{ required: true }]}
        >
          <Upload {...props}>
            <Button icon={<UploadOutlined />}>Upload</Button>
          </Upload>
        </Field>

        <Field {...tailLayout}>
          <AppSubmitButton disabled={disable} htmlType="submit" />
        </Field>
      </Form>
    </div>
  );
};

export default EditEvent;
